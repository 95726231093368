//created by Finn on 2022/12/07
import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Input } from 'antd'

const LimitedWordTextareaContainer = styled.div`
  .limit-wrap {
    display: flex;
    justify-content: flex-end;
    .limit-word-count {
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
    }
  }
`

interface Props {
  value?: any
  rows?: number
  cols?: number
  placeholder?: string
  disabled?: boolean
  limit?: number
  onChange?: (val: any) => void
}

const LimitedWordTextarea = ({
  value,
  rows,
  cols,
  placeholder,
  disabled,
  limit,
  onChange = () => {}
}: Props) => {
  const [{ content, wordCount }, setContent] = useState({
    content: value,
    wordCount: 0
  })

  const setFormattedContent = (text: string) => {
    const words = text.split(' ').filter(Boolean)
    if (limit && words.length > limit) {
      setContent({
        content: text.split(' ').slice(0, limit).join(' '),
        wordCount: limit
      })
      onChange(text.split(' ').slice(0, limit).join(' '))
    } else {
      setContent({ content: text, wordCount: words.length })
      onChange(text)
    }
  }

  useEffect(() => {
    setContent({ content: value, wordCount: value?.split(' ')?.filter(Boolean)?.length || 0 })
  }, [value])

  return (
    <LimitedWordTextareaContainer>
      <Input.TextArea
        rows={rows}
        cols={cols}
        onChange={(event) => setFormattedContent(event.target.value)}
        value={content}
        disabled={disabled}
        placeholder={placeholder}
      />
      <div className="limit-wrap">
        <div className="limit-word-count">
          {wordCount} / {limit}
        </div>
      </div>
    </LimitedWordTextareaContainer>
  )
}

export default LimitedWordTextarea
