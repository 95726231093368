//created by Peggy on 2021/1/29
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Steps,
  Breadcrumb,
  Checkbox,
  message,
  Tooltip,
  Radio,
  Space,
} from "antd";
import { InfoCircleFilled, QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

import Back from "@/components/Back";
import ModalInfo from "@/components/Modal/Info";
import LimitedWordTextarea from "@/components/Input/LimitedWordTextarea";

import { saveSponsor } from "@/api/merbership-api";
import { useModel } from "use-reaction";
import { user } from "@/model/user";

import SelectAndInput from "@/components/Select/SelectAndInput";
import icon_donation from "@/images/icons/icon_donation.svg";
import { view } from "@/model/view";
import { intersection } from "lodash";
import { TheField } from "@/components/GoogleTranslate";

const ApplicationContainer = styled.div`
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }
`;

const Application = () => {
  const {
    store: {
      info: { tags: disciplines, primaryTag, ...others },
    },
  } = useModel(user);
  const {
    store: { isMobile },
  } = useModel(view);

  const [current, setCurrent] = useState(0);
  const [show, setShow] = useState(false);
  const [applySubsidized, setApplySubsidized] = useState("");
  const [applyAutomatically, setApplyAutomatically] = useState("");
  const [form] = Form.useForm();
  const history = useHistory();
  let id = "";
  useEffect(() => {
    form.setFieldsValue({
      ...others,
      primaryTag: Array.isArray(primaryTag) ? primaryTag : [primaryTag],
      disciplines,
    });
    // eslint-disable-next-line
  }, []);
  const onFinish = (values: any) => {
    const {
      firstName,
      lastName,
      companyName,
      email,
      phone,
      alternatePhone,
      website,
      check,
      primaryTag,
      ...others
    } = values;
    let _data = {
      personalInformation: {
        firstName,
        lastName,
        companyName,
        email,
        phone: [phone, alternatePhone || ""],
      },
      artistInformation: {
        ...others,
        primaryTag: Array.isArray(primaryTag) ? primaryTag[0] : primaryTag,
        website: website?.trim(),
        applySubsidized: applySubsidized === "yes" ? true : false,
        applyAutomatically: applyAutomatically === "yes" ? true : false,
      },
    };

    saveSponsor(_data).then((data: any) => {
      setShow(true);
    });
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };
  return (
    <ApplicationContainer className="application-container common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/membership/fiscal">
          Fiscal sponsorship
        </Breadcrumb.Item>
        <Breadcrumb.Item>Fiscal sponsorship application</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Fiscal sponsorship application</h2>
      <Steps current={current}>
        <Steps.Step title="Personal information" />
        <Steps.Step title="Artist information" />
      </Steps>
      <div className="tips">
        Please fully answer each question for your application to be considered
        for approval. Fields marked with an asterisk(<i>*</i>) are required.
      </div>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onKeyPress={(e) => {
          if (e?.key === "Enter") {
            e.preventDefault();
            return false;
          }
        }}
      >
        <div className={current === 0 ? "show" : "hidden"}>
          <div className={isMobile ? "" : "flex"}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: false,
                  whitespace: false,
                  message: "Enter first Name",
                },
              ]}
            >
              <Input maxLength={30} disabled />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: false,
                  whitespace: false,
                  message: "Enter last name",
                },
              ]}
            >
              <Input maxLength={30} disabled />
            </Form.Item>
          </div>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              {
                required: false,
                whitespace: false,
                message: "company Name ",
              },
            ]}
          >
            <Input maxLength={30} disabled />
          </Form.Item>
          <Form.Item
            label="Email address"
            name="email"
            rules={[
              {
                required: false,
                type: "email",
                whitespace: false,
                message: "email invalid",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name="phone"
            normalize={(e) => {
              return e
                .replace(/[^\d]*/g, "")
                .replace(/(\d{3})(?=\d{2})/g, "$1-");
            }}
            rules={[
              {
                required: false,
                whitespace: true,
                message: "Enter phone number",
              },
            ]}
          >
            <Input maxLength={12} placeholder="Enter phone number" disabled />
          </Form.Item>
          <Form.Item
            label="Alternate Phone number"
            name="alternatePhone"
            normalize={(e) => {
              return e
                .replace(/[^\d]*/g, "")
                .replace(/(\d{3})(?=\d{2})/g, "$1-");
            }}
          >
            <Input maxLength={12} placeholder="Enter alternate phone number" />
          </Form.Item>
        </div>
        <div className={current === 1 ? "show" : "hidden"}>
          <Form.Item
            label="Primary Discipline"
            name="primaryTag"
            dependencies={["disciplines"]}
            rules={[
              {
                required: false,
                whitespace: true,
                message: "Primary Discipline",
                validator: (rule, value, cb) => {
                  const otherTags = form.getFieldValue("disciplines");
                  if (!value?.length || !value.filter(Boolean).length) {
                    return Promise.reject(rule.message);
                  }
                  if (intersection(otherTags, value).length) {
                    const error =
                      "Your primary discipline can not be the same as your other disciplines";
                    message.error(error);
                    return Promise.reject(error);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <SelectAndInput mode="single" disabled />
          </Form.Item>
          <Form.Item
            name="disciplines"
            label={
              <div>
                Disciplines
                <Tooltip
                  className="tooltip-field-item"
                  title="Input your discipline here. If you select other, please be sure to hit enter on your keyboard to save your option. "
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </div>
            }
            rules={[
              {
                required: false,
                type: "array",
                message: "Disciplines",
                validator: (rule, value, cb) => {
                  const primaryTag = form.getFieldValue("primaryTag");
                  if (intersection(primaryTag, value).length) {
                    const error =
                      "Your primary discipline can not be the same as your other disciplines";
                    return Promise.reject(error);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <SelectAndInput
              disabled
              placeholderForSelect="Input your discipline here. If you select other, please be sure to hit enter on your keyboard to save your option."
            />
          </Form.Item>
          <Form.Item
            name="website"
            label="Website"
            rules={[
              {
                required: false,
                pattern:
                  /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                message: "website",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="biography"
            label={
              <div>
                BIOGRAPHY
                <Tooltip
                  overlayInnerStyle={{
                    background: "#111",
                    color: "#A2A9AF",
                    width: 600,
                  }}
                  title="Please submit a detailed artistic biography. How did you begin? How long have you been doing it? What are some of your accomplishments? What are you hoping to do next?"
                >
                  <InfoCircleFilled
                    style={{
                      fontSize: 14,
                      color: "#696969",
                      position: "absolute",
                      right: -4,
                      top: -2,
                    }}
                  />
                </Tooltip>
              </div>
            }
            className="textarea-wrap"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Enter biography",
              },
              {
                validator: (_, value) => {
                  if (value?.split(/\s+/g)?.length > 300) {
                    return Promise.reject(
                      new Error("Biography is limited to 300 words")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
                validateTrigger: "blur",
              },
            ]}
          >
            <LimitedWordTextarea
              rows={4}
              limit={300}
              placeholder="Please submit a detailed artistic biography. How did you begin? How long have you been doing it? What are some of your accomplishments? What are you hoping to do next?"
            />
          </Form.Item>
          <Form.Item
            name="practice"
            label={
              <div>
                ARTISTIC PRACTICE + CURRENT PROJECTS
                <Tooltip
                  overlayInnerStyle={{
                    background: "#111",
                    color: "#A2A9AF",
                    width: 600,
                  }}
                  title="Please tell us about your artistic practice and current projects. What kind of art do you make, how, where, with whom do you work? Please include a description of how your work is publically available (i.e. workshops, performances, exhibitions, etc.) and, when are you planning for it to happen."
                >
                  <InfoCircleFilled
                    style={{
                      fontSize: 14,
                      color: "#696969",
                      position: "absolute",
                      right: -4,
                      top: -2,
                    }}
                  />
                </Tooltip>
              </div>
            }
            className="textarea-wrap"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Enter practice and current projects",
              },
              {
                validator: (_, value) => {
                  if (value?.split(/\s+/g)?.length > 300) {
                    return Promise.reject(
                      new Error("Practice and projects is limited to 300 words")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
                validateTrigger: "blur",
              },
            ]}
          >
            <LimitedWordTextarea
              rows={4}
              limit={300}
              placeholder="Please tell us about your artistic practice and current projects. What kind of art do you make, how, where, with whom do you work? Please include a description of how your work is publically available (i.e. workshops, performances, exhibitions, etc.) and, when are you planning for it to happen."
            />
          </Form.Item>
          <Form.Item
            name="missionAndGoals"
            label={
              <div>
                MISSION + FUNDRAISING GOALS
                <Tooltip
                  overlayInnerStyle={{
                    background: "#111",
                    color: "#A2A9AF",
                    width: 600,
                  }}
                  title="Describe the mission of your artistic practice or organization: what drives your work, the intended audience, and desired impact. Additionally, tell us how partnering with The Field as your fiscal sponsor will help you achieve your mission? Tell us about your plans for fundraising (e.g. grant applications, crowdfunding, in kind donations, etc.)."
                >
                  <InfoCircleFilled
                    style={{
                      fontSize: 14,
                      color: "#696969",
                      position: "absolute",
                      right: -4,
                      top: -2,
                    }}
                  />
                </Tooltip>
              </div>
            }
            className="textarea-wrap"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Enter Mission and Goals",
              },
              {
                validator: (_, value) => {
                  if (value?.split(/\s+/g)?.length > 300) {
                    return Promise.reject(
                      new Error("Mission and Goals is limited to 300 words")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
                validateTrigger: "blur",
              },
            ]}
          >
            <LimitedWordTextarea
              rows={4}
              limit={300}
              placeholder="Describe the mission of your artistic practice or organization: what drives your work, the intended audience, and desired impact. Additionally, tell us how partnering with The Field as your fiscal sponsor will help you achieve your mission? Tell us about your plans for fundraising (e.g. grant applications, crowdfunding, in kind donations, etc.)."
            />
          </Form.Item>

          <div className="application-subsidized-social-title">
            Subsidized Fiscal Sponsorship for Social Justice Arts Practitioners
          </div>
          <div className="application-subsidized-social-note">
            <TheField/>’s Fiscal Sponsorship for Social Justice Arts Practitioners
            Program is a subsidized year of fiscal sponsorship with special
            benefits for artists who identify as members of a historically
            underrepresented or marginalized group and whose creative work puts
            into practice and furthers values of social justice, equity, and
            inclusion in their communities. We at <TheField/> understand that
            marginalized artists who engage in “Artivism” often face unique
            financial challenges as a result of creating work which questions
            the status quo. This program aims to address this issue by providing
            access and opportunity in the form of grant reviews, consultation
            calls, and fundraising support.{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.thefield.org/fiscal-sponsorship-social-justice/"
            >
              See important details about the program here.
            </a>
          </div>
          <Form.Item
            name="applySubsidized"
            label={
              <div>
                Are you interested in applying for a free first year of fiscal
                sponsorship under our Subsidized Fiscal Sponsorship program?
              </div>
            }
            className="radio-wrap"
            rules={[
              {
                required: true,
                whitespace: true,
                message:
                  "Should populate the selection of Subsidized Fiscal Sponsorship for Social Justice Arts Practitioners",
              },
              {
                validator: (_, value) => {
                  if (value) {
                    return Promise.resolve();
                  } else {
                    const error =
                      "Should populate the selection of Subsidized Fiscal Sponsorship for Social Justice Arts Practitioners";
                    return Promise.reject(error);
                  }
                },
              },
            ]}
          >
            <Radio.Group
              className="radio-selection"
              onChange={(e) => setApplySubsidized(e.target.value)}
              value={applySubsidized}
            >
              <Space direction="vertical">
                <Radio value="yes">Yes, please</Radio>
                <Radio value="no">
                  No, thanks, I’ll continue with the standard application.
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          {applySubsidized === "yes" && (
            <div>
              <Form.Item
                name="previouslySponsored"
                label='Have you been previously sponsored by The Field?'
                className="radio-wrap"
                rules={[
                  {
                    required: true,
                    whitespace: true
                  }
                ]}
              >
                <Radio.Group className="radio-selection" >
                  <Space direction="vertical">
                    <Radio value='Yes'>Yes</Radio>
                    <Radio value='No'>No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle dependencies={["previouslySponsored"]}>
                {()=>{
                  const temp = form.getFieldValue("previouslySponsored");
                  if(temp === 'Yes'){
                    return <Form.Item
                    name="previouslySponsoredExplain"
                    label='Please explain how your artistic practice has changed since then.'
                    className="textarea-wrap"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please explain how your artistic practice has changed since then. ",
                      },
                      {
                        validator: (_, value) => {
                          if (value?.split(/\s+/g)?.length > 300) {
                            return Promise.reject(
                              new Error("Explain is limited to 300 words")
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                        validateTrigger: "blur",
                      },
                    ]}
                  >
                    <LimitedWordTextarea
                      rows={4}
                      limit={300}
                      placeholder="Please explain how your artistic practice has changed since then. "
                    />
                  </Form.Item>
                  }
                }}
              </Form.Item>
              <Form.Item
                name="valueAlignment"
                label={
                  <div>
                    Value Alignment
                    <Tooltip
                      overlayInnerStyle={{
                        background: "#111",
                        color: "#A2A9AF",
                        width: 600,
                      }}
                      title="Describe how your creative work and mission aligns with The Field's Social Justice Values. How are you putting these values into practice?"
                    >
                      <InfoCircleFilled
                        style={{
                          fontSize: 14,
                          color: "#696969",
                          position: "absolute",
                          right: -4,
                          top: -2,
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                className="textarea-wrap"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Enter Value Alignment",
                  },
                  {
                    validator: (_, value) => {
                      if (value?.split(/\s+/g)?.length > 300) {
                        return Promise.reject(
                          new Error("Value Alignment is limited to 300 words")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                    validateTrigger: "blur",
                  },
                ]}
              >
                <LimitedWordTextarea
                  rows={4}
                  limit={300}
                  placeholder="Describe how your creative work and mission aligns with The Field's Social Justice Values. How are you putting these values into practice?"
                />
              </Form.Item>

              <Form.Item
                name="benefit"
                label={
                  <div>
                    Benefit
                    <Tooltip
                      overlayInnerStyle={{
                        background: "#111",
                        color: "#A2A9AF",
                        width: 600,
                      }}
                      title="Why is a free year of fiscal sponsorship important to you right now? What goals do you hope to be able to achieve if you are accepted?"
                    >
                      <InfoCircleFilled
                        style={{
                          fontSize: 14,
                          color: "#696969",
                          position: "absolute",
                          right: -4,
                          top: -2,
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                className="textarea-wrap"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Enter Benefit",
                  },
                  {
                    validator: (_, value) => {
                      if (value?.split(/\s+/g)?.length > 300) {
                        return Promise.reject(
                          new Error("Benefit is limited to 300 words")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                    validateTrigger: "blur",
                  },
                ]}
              >
                <LimitedWordTextarea
                  rows={4}
                  limit={300}
                  placeholder="Why is a free year of fiscal sponsorship important to you right now? What goals do you hope to be able to achieve if you are accepted?"
                />
              </Form.Item>
            </div>
          )}

          <Form.Item
            name="check"
            noStyle
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Please review and accept the agreement")
                      ),
              },
            ]}
          >
            <Checkbox style={{ marginBottom: 24 }}>
              I have read the{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.thefield.org/the-field-fiscal-sponsorship-artist-agreement-blank/"
              >
                Sponsored Artist contract
              </a>{" "}
              and I agree to the terms therein
            </Checkbox>
          </Form.Item>
        </div>
        <p className="btn-wrap">
          {current === 1 && (
            <span className="flex">
              <Back onClick={() => setCurrent(0)}>
                {isMobile ? "Previous" : "Back"}
              </Back>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </span>
          )}
          {current === 0 && (
            <Button
              type="primary"
              className="application-continue-btn"
              onClick={() => {
                form
                  .validateFields([
                    "first_name",
                    "last_name",
                    "email",
                    "companyName",
                    "phone",
                  ])
                  .then(() => {
                    setCurrent(1);
                  });
              }}
            >
              Continue
            </Button>
          )}
        </p>
      </Form>
      <ModalInfo
        visible={show}
        onClose={() => {
          setShow(false);
          history.push("/dashboard/membership/fiscal");
        }}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setShow(false);
              history.push("/dashboard/membership/fiscal");
            }}
          >
            OK
          </Button>,
        ]}
        img={icon_donation}
      >
        <h4>Application submitted!</h4>
        <div style={{ textAlign: "left" }}>
          {applySubsidized === "yes" ? (
            <p>
              All qualified applications are reviewed and applicants notified
              approximately 1-2 weeks after the deadline of:
              <ul style={{ paddingLeft: "16px" }}>
                <li>
                  January 31 - If you apply between October 1 - January 31
                </li>
                <li>May 31 - If you apply between February 1 - May 31</li>
                <li>
                  September 30 - If you apply between June 1 - September 30
                </li>
              </ul>
            </p>
          ) : (
            <p>
              All applications are reviewed and applicants notified within 5
              business days. After your application is reviewed, you will get an
              email with next steps.
            </p>
          )}
        </div>
      </ModalInfo>
    </ApplicationContainer>
  );
};

export default Application;
