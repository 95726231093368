import { KV, useModel } from "use-reaction";
import { Form, List, Menu, message, Table, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import "./myFavorite.less";
import Search from "antd/lib/input/Search";
import { delFavorite, listFavorite } from "@/api/favorite-api";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { ColumnProps } from "antd/lib/table";
import { view } from "@/model/view";
export enum FavoriteType {
  ARTIST = "artist",
  GRANT = "grant",
  EVENT = "event",
}

const Container = styled.div``;

const tabs = [
  {
    lbl: "Artists",
    tab: FavoriteType.ARTIST,
  },
  {
    lbl: "Grants",
    tab: FavoriteType.GRANT,
  },
  {
    lbl: "Events",
    tab: FavoriteType.EVENT,
  },
];
type Data = {
  id: number;
  name: string;
  des: string;
  img: string;
  createDate: string;
  type: FavoriteType;
};

const pageSize = 12;

const MyFavorite = (props: KV) => {
  const [curTab, setCurTab] = useState(tabs[0].tab);
  const [searchVal, setSearchVal] = useState("");
  const [list, setList] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { store: { isMobile } } = useModel(view)

  const [pageNation, setPageNation] = useState({
    page: 1,
    total: 0,
  });

  const cloumns: ColumnProps<any>[] = [
    {
      title: 'NAME',
      dataIndex: '',
      key: 'f-name',
      render: (_, item) => <div className="user">
        <img className="avatar" src={item.img} alt="avatar" />
        <div className="title">{item.name}</div>
      </div>,
      fixed: 'left'
    },
    {
      title: curTab === "event" ? "LOCATION" : "DISCIPLINE",
      dataIndex: 'des',
      key: 'f-des',
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'createDate',
      key: 'f-date'
    },
    {
      render: (_, item) => <Tooltip
        overlay={
          <Menu
            onClick={({ key }) =>
              menuAction[key](item.id, item.type, item.name)
            }
          >
            <Menu.Item key="view_detail">View details</Menu.Item>
            <Menu.Item key="delete">Delete</Menu.Item>
          </Menu>
        }
      >
        <div className="actions"> ... </div>
      </Tooltip>
    }
  ]
  const doSearch = async (page?: number) => {
    const res = await listFavorite(
      curTab,
      searchVal,
      pageSize,
      page || pageNation.page
    );
    if (res?.items) {
      setList(
        res.items.map((item: any) => {
          switch (item.type) {
            case FavoriteType.ARTIST:
            case FavoriteType.GRANT:
              return {
                id: item.id,
                name: item.name,
                des: Array.isArray(item.discipline)
                  ? item.discipline?.join(",")
                  : item.discipline,
                createDate: dayjs(item.createDate).format("YYYY-MM-DD"),
                img: item.img,
                type: item.type,
              };
            case FavoriteType.EVENT:
              return {
                id: item.id,
                name: item.name,
                des: item.location?.state || item.location?.url || "",
                createDate: dayjs(item.createDate).format("YYYY-MM-DD"),
                img: item.img,
                type: item.type,
              };
          }
        })
      );
      setPageNation({
        ...pageNation,
        total: res.total,
        page: res.items?.length ? page || pageNation.page : pageNation.page,
      });
    }
  };

  const menuAction = useMemo(() => {
    return {
      view_detail: (id: number, type: FavoriteType, name: string) => {
        let router = "";
        switch (type) {
          case FavoriteType.EVENT:
            router = "/event/" + id;
            break;
          case FavoriteType.GRANT:
            router = `/home/grant/${id}/${name.replace(/[^\w]/g, "-")}`;
            break;
          case FavoriteType.ARTIST:
            router = `/profile/${(name || "-").replace(/[^\w]/g, "-")}/${id}`;
        }
        history.push(router);
      },
      delete: async (id: number, type: FavoriteType) => {
        setLoading(true);
        const res = await delFavorite(id, type);
        if (res?.success) {
          message.success("delete success.");
          doSearch();
        }
        setLoading(false);
      },
    };
    // eslint-disable-next-line
  }, []) as any;

  const changePage = (page: number) => {
    doSearch(page);
  };
  useEffect(() => {
    doSearch();
    // eslint-disable-next-line
  }, [searchVal, curTab]);
  return (
    <Container className="common-container my-favorite-container flex column start align-start">
      <h1>Favorites</h1>

      <Table
        className="table-item table-container favorite-list"

        title={
          () => <div className="tabs flex between">
            {tabs.map((cfg) => (
              <div
                className={`tab-btn ${curTab === cfg.tab ? "actived" : ""}`}
                key={cfg.tab}
                onClick={(e) => {
                  setPageNation({ ...pageNation, page: 1 });
                  setCurTab(cfg.tab);
                }}
              >
                {cfg.lbl}
              </div>
            ))}
            <Form onFinish={doSearch}>
              <Search
                size="large"
                name="search"
                value={searchVal}
                placeholder="Search"
                allowClear
                bordered={false}
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </Form>
          </div>
        }
        columns={cloumns}
        dataSource={list}
        pagination={{
          pageSize,
          total: pageNation.total,
          simple: isMobile,
          current: pageNation.page,
          size: "default",
          showSizeChanger: false,
          showLessItems: true,
          onChange: changePage,
        }}
        sticky
        showHeader={!!list?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
      />

    </Container>
  );
};

export default MyFavorite;
