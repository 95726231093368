import { KV } from "use-reaction";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import "./application_detail.less";
import { Button, Breadcrumb, Tag } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { getGrantApply, getGrantApplyNotes } from "@/adminApp/apis/grant-api";
import fileIcon from "@/images/ico_file.svg";
import { TheField } from "@/components/GoogleTranslate";

type Note = {
  id: number;
  type: "link" | "text";
  content: string;
};
const statusTags = ["approved", "rejected"];
export const ApplicationDetail = (props: KV) => {
  const { id } = useParams() as any;
  const [info, setInfo] = useState<any>({});
  const [noteDocs, setNoteDocs] = useState<Note[]>([]);
  const [noteTxt, setNoteTxt] = useState<Note>();

  const history = useHistory();

  const freshNotes = () => {
    getGrantApplyNotes(id).then((res) => {
      const docs: Note[] = [];
      let txt: Note = undefined as any;
      res.items.map((item: Note) => {
        if (item.type === "link") {
          docs.push(item);
        } else if (!txt) {
          txt = item;
        }
      });
      setNoteDocs(docs);
      txt && setNoteTxt(txt);
    });
  };

  useEffect(() => {
    getGrantApply(id).then((res) => {
      if (res?.id) {
        setInfo(res);
        // sort out notes
        freshNotes();
      }
    });
    // eslint-disable-next-line
  }, [id]);
  const status = statusTags.includes(info.status) ? info.status : "pending";
  return (
    <div className="admin-common-container application-review">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href={"/dashboard/grant"}>Grants</Breadcrumb.Item>
        <Breadcrumb.Item>Application details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex start top-bar">
        <h1 className="title">{info.contactName} </h1>
        <Tag
          className="status"
          color={status === "pending" ? "orange" : "green"}
        >
          {status}
        </Tag>
      </div>
      <div className="des">
        In order to publish and event, you will have to submit the content for
        <TheField/> to review. The approval process will take no more than 5
        business days
      </div>
      <div className="sub-infos">
        <h3 className="sub-title">Grant information</h3>
        <div className="flex justify">
          <div className="key">Application Type</div>
          <div className="val">Grant</div>
        </div>
        <div className="flex justify">
          <div className="key">Grant Name</div>
          <div className="val">{info.grant_name}</div>
        </div>
        <div className="flex justify">
          <div className="key">Organization</div>
          <div className="val">{info.grant_organization}</div>
        </div>
        <div className="flex justify">
          <div className="key">Deadline</div>
          <div className="val">
            {info.grant_deadlineType === "hard"
              ? dayjs(info.grant_deadline).format("MM/DD/YYYY")
              : "N/A"}
          </div>
        </div>
      </div>
      <div className="sub-infos">
        <h3 className="sub-title">Artist notes</h3>
        <div className="artist-notes">{info.notes}</div>
      </div>
      <div className="sub-infos">
        <h3 className="sub-title">Admin notes</h3>
        <div className="artist-notes">{noteTxt?.content}</div>
      </div>
      <div className="sub-infos">
        <h3 className="sub-title">Documents</h3>
        <div className="flex wrap documents">
          {noteDocs.map((d: Note) => (
            <div className="flex start doc">
              <img src={fileIcon} alt="file" />
              <a key={d.id} href={d.content}>
                {d.content.split("/").pop()}
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="flex end btns">
        <Button
          onClick={(e) => history.push("/dashboard/message")}
          className="btn-msg"
        >
          Send message
        </Button>
      </div>
    </div>
  );
};
