import { donationTypes, paymentTypes, statusTypes } from "@/types/enum";
import { Button, Dropdown, Input, Menu, Select, Table, Tag, Tooltip } from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { incomeArchiveList, incomeCheckHistoryList } from "@/api/donation-api";
import "./IncomeArchivedHistory.less";
import { useModel } from "use-reaction";
import queryString from "query-string";
import { view } from "@/model/view";
import DatePicker from "@/components/DatePicker";
import { user } from "@/model/user";
import { QuestionCircleOutlined } from "@ant-design/icons";
export const IncomeArchivedHistory = () => {
  const [type, setType] = useState<"archived" | "check">("archived");
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
  });
  const history = useHistory();
  const { store: { info: { id: userId } } } = useModel(user)
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState<any[]>();
  const [summary, setSummary] = useState<any>({});
  const fetcher = {
    archived: incomeArchiveList,
    check: incomeCheckHistoryList,
  };
  const handLink = (row: any) => {
    if (row.type === "donation_to_artist" || row.type === "crowdfunding") {
      history.push(`/dashboard/income/detail/${row.id}`);
    } else {
      history.push(`/dashboard/donation/inKind/${row.id}`);
    }
  };
  const handleExport = () => {
    const { page, limit, date } = params;
    const [startTime, endTime] = date || [null, null];
    let _data = {
      startTime: startTime?.toDate(),
      endTime: endTime?.toDate(),
      archived: true,
      userId
    };
    window.open(
      `${process.env.REACT_APP_API_HOST
      }/income/export-csv?${queryString.stringify({
        ..._data,
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  const column: any[] =
    type === "archived"
      ? [
        {
          title: "Donation type",
          dataIndex: "type",
          width: 110,
          ellipsis: true,
          fixed: "left",
          render: (text: string, row: any) => donationTypes[text || "_null"],
        },
        {
          title: "Donor",
          dataIndex: "donor",
          width: 130,
          ellipsis: true,
        },
        {
          title: "Donor email",
          dataIndex: "donorEmail",
          ellipsis: true,
        },
        {
          title: "Payment method",
          width: 180,
          dataIndex: "paymentMethod",
          render: (text: string, row: any) => paymentTypes[text || "_null"],
        },
        {
          title: "Donation Date",
          width: 150,
          dataIndex: "createDate",
          render: (text: string, row: any) =>{
            if(row.paymentMethod === 'check'){
              if(row.status === 'check_pending'){
                return 'N/A';
              }
              return dayjs(row.billDate || row.createDate).format("MM/DD/YYYY")
            }else{
              return dayjs(row.createDate).format("MM/DD/YYYY")
            }
          },
        },
        // {
        //     title: "Schedule PMT date",
        //     dataIndex: "receivedDate",
        //     render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
        // },
        {
          title: (
            <>
              STATUS
              <Tooltip
                overlay={
                  <ul>
                    <li>
                      Paid - Donation successful; payment to artist scheduled or
                      complete.
                    </li>
                    <li>
                      Pending - Donation initiated; verifying payment information.
                    </li>
                    <li>
                      Check Pending - Check entered online; will process upon
                      receipt.
                    </li>
                    <li>
                      Processing - Donation successful; payment to artist scheduled
                      for 10 days later.
                    </li>
                    <li>
                      Failed - Donor’s payment information incomplete or declined.
                    </li>
                  </ul>
                }
              >
                <QuestionCircleOutlined
                  size={12}
                  style={{ padding: 6, minWidth: 20 }}
                />
              </Tooltip>
            </>
          ),
          width: 110,
          dataIndex: "status",
          render: (text: string, _: any) => (
            <Tag color={statusTypes[text ?? "processing"].color}>
              {statusTypes[text ?? "processing"].label}
            </Tag>
          ),
        },
        {
          title: "Total Amount",
          dataIndex: "amount",
          width: 150,
          render: (text: number) =>
            Number(text || 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "",
          width: 80,
          align: "right",
          render: (_: any, row: any) => (
            <Dropdown
              trigger={["click"]}
              overlay={() => (
                <>
                  <Menu>
                    <Menu.Item onClick={() => handLink(row)}>
                      View Order Detail
                    </Menu.Item>
                    <Menu.Item
                      hidden={row.status === 'failed'}
                      onClick={() =>
                        history.push(
                          `/dashboard/income/detail/${row.id}/note`
                        )
                      }
                    >
                      View thank you note
                    </Menu.Item>
                  </Menu>
                </>
              )}
            >
              <span className="ant-dropdown-link">
                <FilterMore />
              </span>
            </Dropdown>
          ),
        },
      ]
      : [
        {
          title: "Payment date",
          dataIndex: "billDate",
          width: 160,
          fixed: "left",
          render: (text: string, row: any) =>
            dayjs(text).format("MM/DD/YYYY"),
        },
        {
          title: "received date",
          dataIndex: "createDate",
          width: 160,
          render: (text: string, row: any) =>
            dayjs(text).format("MM/DD/YYYY"),
        },
        {
          title: "Status",
          dataIndex: "",
          key: "status",
          render: (text: string, _: any) => <Tag color="green"> Paid </Tag>,
        },
        {
          title: "Total Amount",
          dataIndex: "totalAmount",
          key: "amount",
          render: (text: number) =>
            Number(text || 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "Fee",
          dataIndex: "fees",
          key: "fees",
          render: (text: number) =>
            Number(text || 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            }),
        },
        {
          title: "Received Amount",
          dataIndex: "amount",
          key: "received",
          render: (text: number) =>
            Number(text || 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            }),
        },
      ];

  const getList = () => {
    fetcher[type]({ ...params })
      .then(({ items, meta, summary }: any) => {
        setTableData(items);
        setTotal(meta?.totalItems || items.length);
        setSummary(summary);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
      });
  };

  const {
    store: { isMobile },
  } = useModel(view);
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params, type]);
  const title = type === "archived" ? "Archived transactions" : "Check History";
  return (
    <div className="common-container income-archived-history">
      <h1>{title}</h1>
      <div className={`search-container ${isMobile ? "" : "flex"} start`}>
        <div className="field-form-items">
          <span>Viewing</span>
          <Select
            value={type}
            bordered={false}
            onChange={(e) => {
              setType(e);
              setTableData([]);
            }}
            placeholder="Select product types"
          >
            <Select.Option value="archived">
              {" "}
              Archived transactions{" "}
            </Select.Option>
            <Select.Option value="check"> Check History </Select.Option>
          </Select>
        </div>
        <div className="field-form-items">
          <span>Date range</span>
          <DatePicker.RangePicker
            onChange={(e) => {
              setParams({ ...params, page: 1, date: e });
            }}
          />
        </div>
        <Button type="primary" hidden={type === 'check'} onClick={handleExport}>Export to Csv</Button>
      </div>
      {/* <div className="table-container"> */}
        <Table
          className="table-items table-container"
          title={() => (
            <div className="flex">
              <span>{title}</span>
              {type === "archived" && (
                <Input.Search
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onSearch={(e) => {
                    setTableData([]);
                    setParams({
                      ...params,
                      page: 1,
                      limit: 10,
                      keyword: e,
                    });
                  }}
                  placeholder="Search"
                />
              )}
            </div>
          )}
          rowKey="id"
          pagination={
            type === "archived"
              ? {
                total,
                current: params.page,
                pageSize: params.limit,
                showTotal: (total: any) => `Total :  ${total} `,
                onChange: (page, pageSize) =>
                  setParams({
                    ...params,
                    page,
                    limit: pageSize,
                  }),
              }
              : undefined
          }
          columns={column}
          dataSource={tableData}
          sticky
          showHeader={!!tableData?.length}
          scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
          summary={() =>
            type === "archived" ? (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}> Total </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  {Number(summary?.amount || 0).toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            ) : (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {Number(summary?.total_amount || 0).toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {Number(summary?.fees || 0).toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {Number(summary?.amount || 0).toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }
        />
      {/* </div> */}
    </div>
  );
};
