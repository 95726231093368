//created by Peggy on 2021/3/29
import React, { memo, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Divider,
  Dropdown,
  Empty,
  Menu,
  message,
  Modal,
  Tag,
} from "antd";
import DatePicker from "@/components/DatePicker";

import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { getEnv } from "@/libs/cfg";

import icon_donation from "@/images/icons/icon_donation.svg";

import styled from "styled-components";
import avatar from "@/images/avatar.svg";

import EventStep from "./Step";

import {
  eventDelete,
  eventListLive,
  eventListPast,
  eventPut,
} from "@/api/event-api";
import Info from "@/components/Modal/Info";
import { useModel } from "use-reaction";
import { view } from "@/model/view";

const ListContainer = styled.div``;

const localizedFormat = require("dayjs/plugin/localizedFormat");
const dayjs = require("dayjs");
dayjs.extend(localizedFormat);

const List = () => {
  const history = useHistory();
  //list
  const [onList, setOnList] = useState([]);
  const [pastList, setPastList] = useState([]);
  const [live] = useState(0);
  const [past, setPast] = useState<any>();
  const [current, setCurrent] = useState({
    id: 0,
    name: "",
  });
  const {
    store: { isMobile },
  } = useModel(view);
  const getOnList = () => {
    eventListLive().then((data: any) => {
      setOnList(data);
    });
  };
  const getPastList = () => {
    let _data = past;
    eventListPast(_data).then((data: any) => {
      setPastList(data);
    });
  };

  useEffect(() => {
    getOnList();
  }, [live]);

  useEffect(() => {
    getPastList();
    // eslint-disable-next-line
  }, [past]);

  const handleSave = (type: string, id: number, name: string) => {
    let _data = {
      status: type,
    };
    eventPut(id, _data).then((data) => {
      getOnList();
      setCurrent({
        id,
        name,
      });
      type === "live" && setShow(true);
    }).catch(() => {
      history.push(`/dashboard/event/create/${id}?checkField=true`);
    });
  };
  const handleClose = (id: number, status: string, name: string) => {
    Modal.confirm({
      title: "Are you sure you want to unpublish event ?",
      icon: <ExclamationCircleOutlined />,
      content: `You are about to unpublish ${name}. The event will remain as a saved draft until you publish or delete the event. `,
      okText: "unpublish event",
      cancelText: "cancel",
      onOk: () => {
        let _data = {
          status: "draft",
        };
        eventPut(id, _data).then((data) => {
          getOnList();
          message.success("Event Unpublished");
        });
      },
    });
  };
  const handleDelete = (id: number, status: string, name: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      icon: <ExclamationCircleOutlined />,
      content: `You are deleting a ${status} version of the event ${name}. This action can’t be undone. `,
      okText: "Delete",
      cancelText: "cancel",
      onOk: () => {
        let _data = {
          id,
        };
        eventDelete(_data).then((data) => {
          getOnList();
          message.success("delete success");
        });
      },
    });
  };
  const handleEdit = (id: number) => {
    history.push(`/dashboard/event/create/${id}`);
  };

  const menu = ({ status, name, id, publish }: any) => (
    <Menu>
      <Menu.Item onClick={() => handleEdit(id)}>Edit</Menu.Item>
      {status === "draft" && (
        <Menu.Item onClick={() => handleDelete(id, status, name)}>
          Delete
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() =>
          history.push(
            `/event/${id}${
              status === "draft" ? "/edit" : `/${name?.replace(/[^\w]/g, "-")}`
            }`
          )
        }
      >
        {status === "draft" ? "Preview" : "View"} live site
      </Menu.Item>
    </Menu>
  );

  //info
  const [show, setShow] = useState(false);
  return (
    <>
      {((onList?.length || pastList?.length) && (
        <ListContainer className="event-list-container">
          <h1 className="flex">
            Events{" "}
            <Button
              type="primary"
              onClick={() => history.push("/dashboard/event/create")}
            >
              {isMobile ? "new event" : "POST A new event"}
            </Button>
          </h1>
          {onList!.length ? (
            <>
              <div className="campaign-on-wrap">
                <h4 className="title" hidden={isMobile}>
                  On-going events
                </h4>
                {onList!.map((item: any) =>
                  isMobile ? (
                    <EventItemMobile
                      item={item}
                      type="on"
                      history={history}
                      menu={menu}
                      handleSave={handleSave}
                      handleClose={handleClose}
                    />
                  ) : (
                    <div key={item.name} className="campaign-list">
                      <div className="flex start">
                        <img src={item?.thumbnail || avatar} alt="" />
                        <div className="flex campaign-info-wrap">
                          <div className="flex column start align-start info-title ">
                            <h2 className="flex start">
                              {item?.name}
                              {item.status === "draft" ? (
                                <Tag color="gray">Draft</Tag>
                              ) : (
                                <Tag color="blue">live</Tag>
                              )}
                            </h2>
                            <ul>
                              <li>
                                <p>
                                  <span>Date</span>{" "}
                                  <i>
                                    {item?.time?.startTime
                                      ? dayjs(item?.time?.startTime).format(
                                          "HH:mm  A"
                                        )
                                      : "N/A"}{" "}
                                    {item?.time?.endTime
                                      ? `- ${dayjs(item?.time?.endTime).format(
                                          "HH:mm  A"
                                        )}`
                                      : ""}{" "}
                                    {item?.time?.timezone ?? "N/A"}・
                                    {item?.time?.startDate
                                      ? dayjs(item?.time?.startDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                    {item?.time?.endDate
                                      ? `- ${dayjs(item?.time?.endDate).format(
                                          "MM/DD/YYYY"
                                        )}`
                                      : ""}
                                  </i>
                                </p>
                              </li>
                              <li>
                                <p>
                                  <span>location</span>{" "}
                                  {(item?.location?.type === "online" &&
                                    ((item?.location?.url?.indexOf("http") >
                                      -1 && (
                                      <Link to={item?.location?.url}>
                                        {item?.location?.url}
                                      </Link>
                                    )) || <i>{item?.location?.url}</i>)) || (
                                    <i>{item?.location?.address}</i>
                                  )}
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="flex column align-end info-btn">
                            <span>
                              Last updated on
                              {" " +
                                dayjs(item.updateDate).format(
                                  "DD/MM/YYYY h:mm A"
                                )}
                            </span>
                            <div className="btn flex end">
                              <Dropdown
                                trigger={["click"]}
                                overlay={() => menu(item)}
                              >
                                <span className="ant-dropdown-link">
                                  <EllipsisOutlined />
                                </span>
                              </Dropdown>
                              {item?.status === "live" ? (
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    handleClose(item.id, item.status, item.name)
                                  }
                                >
                                  unpublish
                                </Button>
                              ) : (
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    handleSave("live", item.id, item.name)
                                  }
                                >
                                  Publish
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <Divider />
            </>
          ) : (
            ""
          )}
          <div className="range-picker-wrap field-form-items">
            <span>Date range</span>
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              onChange={(e) => {
                const [start, end] = e || ["", ""];
                setPast({
                  start,
                  end,
                });
              }}
            />
          </div>
          <div className="campaign-on-wrap campaign-past-wrap">
            <h4 className="title" hidden={isMobile}>
              Past events
            </h4>
            {pastList?.length ? (
              pastList?.map((item: any) =>
                isMobile ? (
                  <EventItemMobile
                    item={item}
                    type="past"
                    history={history}
                    menu={menu}
                    handleSave={handleSave}
                    handleClose={handleClose}
                  />
                ) : (
                  <div key={item.name} className="campaign-list">
                    <div className="flex start">
                      <img src={item?.photos ? item?.photos[0] : avatar} alt="" />
                      <div className="flex campaign-info-wrap">
                        <div className="flex column start align-start info-title">
                          <h2>{item?.name}</h2>
                          <ul>
                            <li>
                              <p>
                                <span>Date</span>{" "}
                                <i>
                                  {dayjs(item?.time?.startTime).format(
                                    "HH:mm  A"
                                  )}{" "}
                                  {item?.time?.endTime
                                    ? `- ${dayjs(item?.time?.endTime).format(
                                        "HH:mm  A"
                                      )}`
                                    : ""}{" "}
                                  {" " + item?.time?.timezone}・
                                  {item?.time?.startDate
                                    ? dayjs(item?.time?.startDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "N/A"}{" "}
                                  {item?.time?.endDate
                                    ? `- ${dayjs(item?.time?.endDate).format(
                                        "MM/DD/YYYY"
                                      )}`
                                    : ""}
                                </i>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>location</span>{" "}
                                {(item?.location?.type === "online" &&
                                  ((item?.location?.url?.indexOf("http") >
                                    -1 && (
                                    <a
                                      rel="noreferrer"
                                      target="_blank"
                                      href={item?.location?.url}
                                    >
                                      {item?.location?.url}
                                    </a>
                                  )) || <i>{item?.location?.url}</i>)) || (
                                  <i>{item?.location?.address}</i>
                                )}
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="flex column align-end info-btn">
                          <span>
                            {0} Views・{0} Clicks
                          </span>
                          <Button
                            onClick={() =>
                              history.push(
                                `/event/${item.id}/${(item.name || "").replace(
                                  /[^\w]/g,
                                  "-"
                                )}`
                              )
                            }
                          >
                            View detail
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <Empty description={false} />
            )}
          </div>
          <InfoModal {...{ current, history, show, setShow }} />
        </ListContainer>
      )) || <EventStep />}
    </>
  );
};

const EventItemMobile = ({
  item,
  type,
  history,
  menu,
  handleClose,
  handleSave,
}: any) => {
  return (
    <div key={item.name} className="event-item-mobile">
      <h3>{type === "on" ? "On-going event" : "Past event"}</h3>
      <Divider type="horizontal" />
      <img src={item?.thumbnail || avatar} alt="" />
      {type === "on" && (
        <Tag color={item.status === "draft" ? "default" : "blue"}>
          {item.status}
        </Tag>
      )}
      <h2>{item?.name}</h2>
      {type === "on" ? (
        <span className="sub-tip">
          Last updated on
          {" " + dayjs(item.updateDate).format("DD/MM/YYYY h:mm A")}
        </span>
      ) : (
        <span className="sub-tip">
          {0} Views・{0} Clicks
        </span>
      )}
      <div className="campaign-info-wrap">
        <div className="column start align-start info-title">
          <ul>
            <li>
              <p className="flex align-start">
                <span>Date</span>
                <i>
                  {dayjs(item?.time?.startTime).format("HH:mm  A")}{" "}
                  {item?.time?.endTime
                    ? `- ${dayjs(item?.time?.endTime).format("HH:mm  A")}`
                    : ""}{" "}
                  {" " + item?.time?.timezone + " "}
                  {item?.time?.startDate
                    ? dayjs(item?.time?.startDate).format("MM/DD/YYYY")
                    : "N/A"}{" "}
                  {item?.time?.endDate
                    ? `- ${dayjs(item?.time?.endDate).format("MM/DD/YYYY")}`
                    : ""}
                </i>
              </p>
            </li>
            <li>
              <p className="flex start align-start">
                <span>location</span>
                {(item?.location?.type === "online" &&
                  ((item?.location?.url?.indexOf("http") > -1 && (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={item?.location?.url}
                    >
                      {item?.location?.url}
                    </a>
                  )) || <i>{item?.location?.url}</i>)) || (
                  <i>{item?.location?.address}</i>
                )}
              </p>
            </li>
          </ul>
        </div>
        <Divider type="horizontal" />
        {type === "on" ? (
          <div className="btn flex end">
            <Dropdown trigger={["click"]} overlay={() => menu(item)}>
              <span className="ant-dropdown-link">
                <EllipsisOutlined />
              </span>
            </Dropdown>
            {item?.status === "live" ? (
              <Button
                type="primary"
                onClick={() => handleClose(item.id, item.status, item.name)}
              >
                unpublish
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => handleSave("live", item.id, item.name)}
              >
                Publish
              </Button>
            )}
          </div>
        ) : (
          <div className="flex column align-end info-btn">
            <Button
              onClick={() =>
                history.push(
                  `/event/${item.id}/${(item.name || "").replace(
                    /[^\w]/g,
                    "-"
                  )}`
                )
              }
            >
              View detail
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

interface InfoModalProps {
  current: {
    id: string | number;
    name: string;
  };
  history: any;
  show: boolean;
  setShow: (val: boolean) => void;
}

export const InfoModal = memo(
  ({ current, history, show, setShow }: InfoModalProps) => (
    <Info
      className="event-info-modal"
      visible={show}
      width={660}
      footer={[
        <Button onClick={() => history.push("/dashboard/message")}>
          contact us
        </Button>,
        <Button onClick={() => setShow(false)} type="primary">
          Got it
        </Button>,
      ]}
      img={icon_donation}
    >
      <h1>
        Congratulations!
        <br />
        Your event has been posted!
      </h1>
      <p>
        You can share your artist profile on social media and manage your events
        through your artist dashboard.
      </p>
      <div className="field-form-items flex column start align-start">
        <span>EVENT url</span>
        <CopyToClipboard
          text={`${getEnv("WEB_HOST")}/event/${
            current.id
          }/${current.name.replace(/[^\w]/g, "-")}`}
          onCopy={() => message.success("Link copied")}
        >
          <p className="flex">
            <i>{`${getEnv("WEB_HOST")}/event/${
              current.id
            }/${current.name.replace(/[^\w]/g, "-")}`}</i>
            <span>copy Link</span>
          </p>
        </CopyToClipboard>
      </div>
    </Info>
  )
);

export default List;
