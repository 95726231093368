//created by Peggy on 2021/6/21
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, Form, Input, InputNumber, message, Radio, Select } from "antd";
import HomeLayout from "@/components/layouts/HomeLayout";
import StripePayCom from "@/components/StripePayment";

import {
  cancelIntent,
  consultByStripe,
  couponCheck,
  eventMakeOrder,
} from "@/api/consultation-api";

import { ReactComponent as CreditCard } from "@/images/credit_card.svg";
import { stateList } from "@/types/enum";

import "@/styles/home/checkout.less";
import styled from "styled-components";
import { getToken, localStorageGet } from "@/utils/storage";
import { getBillInfo } from "@/api/donation-api";
import { getUserInfo } from "@/api/sign-api";
import { user } from "@/model/user";
import Warning from "@/components/Modal/Warning";
import { TheField } from "@/components/GoogleTranslate";
import IsThisCorrect from "../../donation/IsThisIncorrect";
import { useModel } from "use-reaction";
const { getData: getCountryList } = require('country-list');
const countryList = getCountryList()

const ConsultationContainer = styled.div`
  .field-form-items.field-required {
    padding-right: 16px;
    margin-bottom: 24px;
  }
`;

const StripePay = React.forwardRef<any, any>((props, ref) => (
  <StripePayCom {...props} submitRef={ref} />
));

const Consultation = () => {
  const history = useHistory();
  const submitRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [secretId, setSecretId] = useState<any>();
  const [intentId, setIntentId] = useState<any>();
  const [required, setRequired] = useState<any[]>([]);
  const { store: userStore } = useModel(user);
  const [warning, setWarning] = useState(false);

  const [pay, setPay] = useState<any>({
    payment: "credit card",
    address: "",
    email: "",
    name: "",
    city: "",
    state: "",
    country: "US",
    code: "",
  });

  const [order, setOrder] = useState<any>();
  const [amount, setAmount] = useState(0);
  const [eventType, setEventType] = useState('')
  const [addon, setAddon] = useState<any>({
    fieldAmount: "",
    contribution: false,
  });

  useEffect(() => {
    let { promo, addon: addonInfo, type, ...others } = localStorageGet("checkout-event");
    setOrder(others);
    promo && setPromo(promo);
    setAddon(addonInfo);
    setEventType(type);
    if (getToken()) {
      getBillInfo({}, true).then((data) => {
        const {
          billing_details: {
            address: { city, line1: address, postal_code: code, state, country },
            ...others
          },
        } = (Object.values(data)?.length && data) || {
          billing_details: {
            address: {
              city: "",
              line1: "",
              postal_code: "",
              state: "",
              country: "US",
            },
          },
        };
        let _pay = {
          ...pay,
          ...others,
          city,
          address,
          code,
          state,
          country: country || 'US',
          name:(userStore.info.firstName + ' ' + userStore.info.lastName).trim(),
          email:userStore.info.email,
        };
        setPay(_pay);
        form.setFieldsValue(_pay);
      });
      if (
        others?.model === "pay_as_you_wish" &&
        others.ticketModel === "single"
      ) {
        getUserInfo().then(({ roles, sponsorshipValid }) => {
          let _flag = 0;
          if (roles?.includes("donor") && !roles.includes("artist")) {
            if (!others?.eligibleUserRole?.includes("donor")) {
              _flag = 1;
            }
          } else if (roles?.includes("artist") && !sponsorshipValid) {
            if (!others?.eligibleUserRole?.includes("free_artist")) {
              _flag = 1;
            }
          } else if (roles?.includes("artist") && sponsorshipValid) {
            if (!others?.eligibleUserRole?.includes("sponsored_artist")) {
              _flag = 1;
            }
          }
          if (_flag === 1) {
            setWarning(true);
            return false;
          }
        });
      }
    }
  }, []);


  const [promo, setPromo] = useState<any>({
    name: "",
    discount: 0,
    error: false,
  });
  const couponValidate = (name: string) => {
    if (!name) {
      setPromo({ name, discount: 0 });
      return false;
    }
    couponCheck({
      name,
      productType: "the_field_ticket",
      price: order?.price,
      extraId: order?.orderId - 0,
    })
      .then((data) =>
        setPromo({ name, discount: data?.discount, error: false })
      )
      .catch((e) => {
        setPromo({
          name: "",
          discount: 0,
          error: true,
        });
      });
  };

  const onFinish = async (values: any) => {
    setRequired([]);

    let _temp = [];

    if (
      order?.model === "pay_as_you_wish" &&
      order.ticketModel === "single" &&
      (!order?.price || (order?.price && order?.price < order?.minAmount))
    ) {
      _temp.push("amount");
      setRequired(_temp);
      message.error(
        `The minimum accepted value is $${order?.minAmount}. Please try again`
      );
      return false;
    }
    let params = {
      participants: [
        {
          price: order.price,
          email: values?.email,
          firstName: values?.name?.split(/\s+/)[0],
          lastName: values?.name?.split(/\s+/)[1],
          customTierName: order?.model,
        },
      ],
      ...order?.data,
      promoCode: promo.name,
      ...(addon.fieldAmount
        ? {
          fieldAmount: addon.fieldAmount,
        }
        : {}),
    };
    let _data = await eventMakeOrder(params);
    setOrder({
      ...order,
      orderId: _data.id,
    });
    let data = await consultByStripe({ orderId: _data.id, coupon: promo.name });
    if(data?.noNeedToPay){
      history.push(`/checkout/success?source=event`);
      return
    }
    setIntentId(data?.id);
    await setSecretId(data?.client_secret);
    await setPay({ ...pay, ...values });
    await submitRef?.current?.submit()
  };

  const onPaymentFail = (error:any) => {
    intentId && cancelIntent(intentId,error)
  }

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);

  };


  useEffect(() => {
    setAmount(
      parseFloat(addon.fieldAmount || "0") + parseFloat(order?.price || "0")
    );
  }, [addon.fieldAmount, order?.price]);


  return (
    <HomeLayout style={{ backgroundColor: "#f1f1f6" }}>
      <ConsultationContainer className="consultation-container">
        <h1>Check out</h1>
        <h2>You're purchasing a ticket to {order?.name}</h2>
        {
          eventType === 'Development Events' && <div className="dev-evt-tip">
            This event benefits <TheField/> and our programs and services for independent artists. 
            Please join us at the benefit ticket level that feels right for you! 
            If you are unable to attend but would like to support our work, <a target='_blank' href='https://app.thefield.org/contribute-to-the-field' rel="noreferrer">please consider making a tax-deductible donation here.</a>
          </div>
        }
        <div className="flex align-start">
          <div className="card-wrap left-wrap">
            {/* {order?.model === "pay_as_you_wish" &&
              order.ticketModel === "single" && (
                <>
                  <h2>Amount</h2>
                  <div
                    className={`field-form-items field-required ${
                      required.includes("amount") ? "field-error-item" : ""
                    }`}
                  >
                    <span>Pay as you wish Amount per ticket</span>
                    <AmountInput
                      onChange={(e) => {
                        const _temp = JSON.parse(JSON.stringify(required));
                        _temp.splice(
                          _temp.findIndex((item: any) => item === "amount"),
                          1
                        );
                        setRequired(_temp);
                        setOrder({
                          ...order,
                          price: e?.replace(/,/g, "") || 0,
                        });
                      }}
                      placeholder={`The minimum value is ${order?.minAmount}`}
                    />
                  </div>
                </>
              )} */}
            <div className="flex column start align-start">
              <h2>Payment method</h2>
              <Radio.Group
                defaultValue="credit card"
                className="card-radio"
                size="large"
                onChange={(e) => {
                  setPay({
                    ...pay,
                    payment: e.target.value,
                  });
                }}
              >
                <Radio.Button value="credit card">
                  <CreditCard />
                  Credit card
                </Radio.Button>
              </Radio.Group>
            </div>
            <Divider />
            <h2>Credit card information</h2>
            <StripePay
              secretId={secretId}
              payId={order?.orderId}
              payInfo={{ ...pay }}
              ref={submitRef}
              onFail={onPaymentFail}
              onNext={() => {
                history.push(`/checkout/success?source=event`);
              }}
            />
            <Divider />
            <h2>Billing information </h2>
            <IsThisCorrect isStep2/>
            <Form
              layout="vertical"
              size="large"
              name="basic"
              form={form}
              initialValues={{
                ...pay,
                name:(userStore.info.firstName + ' ' + userStore.info.lastName).trim(),
                email:userStore.info.email
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={(val)=>{
                if (val.country) {
                  form.setFieldsValue({ state: '', code:'' })
                }
              }}
            >
              <Form.Item
                label="Full Name"
                name="name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Enter full Name",
                  },
                ]}
              >
                <Input disabled={!!userStore.token} size="large" placeholder="Enter full Name" />
              </Form.Item>
              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Enter Email address",
                  },
                ]}
              >
                <Input disabled={!!userStore.token} size="large" placeholder="Enter Email address" />
              </Form.Item>
              <Form.Item
                label="Billing Address"
                name="address"
                rules={[
                  {
                    required: true,
                    whitespace: false,
                    message: "Enter billing address",
                  },
                ]}
              >
                <Input size="large" placeholder="Address" />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Choose Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  size="large"
                  placeholder="Country"
                  optionFilterProp="label"
                  options={countryList.map((item:any) => ({
                    value:item.code,label:item.name
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Enter city",
                  },
                ]}
              >
                <Input size="large" placeholder="City" />
              </Form.Item>
              <div className="flex start align-start row-flex">
                <Form.Item noStyle dependencies={['country']}>
                  {
                    ({ getFieldValue }) => {
                      return getFieldValue('country') === 'US' ?
                        <Form.Item
                          label="State"
                          name="state"
                          className="select-item"
                          rules={[
                            {
                              required: true,
                              whitespace: false,
                              message: "Select State",
                            },
                          ]}
                        >

                          <Select
                            showSearch
                            size="large"
                            placeholder="State"
                          >
                            {Object.values(stateList)?.map((item) => (
                              <Select.Option value={item} key={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item> :
                        <Form.Item
                          label="State/Province"
                          name="state"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Enter State",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="State" />
                        </Form.Item>
                    }
                  }
                </Form.Item>
                <Form.Item noStyle dependencies={["country"]}>
                  {({ getFieldValue }) => {
                    return getFieldValue("country") === "US" ? (
                      <Form.Item
                      label="Zip Code/Postal Code"
                      name="code"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please enter your 5-digit zip code.',
                          validator: (_, value) => {
                            if(/(^\d{5}$)/.test(value)){
                              return Promise.resolve()
                            }else{
                              return Promise.reject()
                            }
                          }
                        },
                      ]}
                    >
                      <Input style={{width:'100%'}} onBeforeInput={(e:any) => {
                          if (!/^\d*$/.test(e.data)) {
                            e.preventDefault();
                          }
                        }} maxLength={5} placeholder="Zip Code" />
                    </Form.Item>
                    ) : (
                      <Form.Item
                      label="Zip Code/Postal Code"
                      name="code"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please enter your zip code.'
                        },
                      ]}
                    >
                      <Input maxLength={10} placeholder="Zip Code" />
                    </Form.Item>
                    );
                  }}
                </Form.Item>
              </div>
            </Form>
            {/* <Divider />
            <div className="flex column start align-start">
              <h2>
                Contribute to The Field
                <Tooltip title="Contributions to The Field help us serve and support independent performing artists and their companies. Thank you!">
                  <InfoCircleFilled
                    style={{ fontSize: 16, color: "#696969", marginLeft: 4 }}
                  />
                </Tooltip>
              </h2>
              <Radio.Group
                value={addon.contribution}
                className="frequency-radio"
                size="large"
                onChange={(e) => {
                  setAddon({
                    ...addon,
                    fieldAmount: "",
                    contribution: e.target.value,
                  });
                }}
              >
                <Radio.Button value={false}>No, thanks</Radio.Button>
                <Radio.Button value={true}>Yes, I’d love to</Radio.Button>
              </Radio.Group>

              {addon.contribution && (
                <div className="field-form-items">
                  <span>Amount </span>
                  <Input
                    size="large"
                    placeholder="Enter amount"
                    prefix="$"
                    value={addon.fieldAmount}
                    onChange={(e) => {
                      const fieldAmount = e.target.value.replace(
                        /[^\d\\.]+/g,
                        ""
                      );
                      setAddon({
                        ...addon,
                        fieldAmount,
                      });
                    }}
                  />
                </div>
              )}
            </div> */}
          </div>
          <div className="card-wrap right-wrap">
            <h3>Order summary</h3>
            <p className="flex">
              <span>Event</span>
              {order?.name}
            </p>
            <p className="flex">
              <span>Price</span>
              {Number(order?.price).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </p>
            <p className="flex align-start">
              <span>Quantity</span>
              <span className="flex column align-end">
                {order?.model !== "custom_tier"
                  ? order?.quantity || 0
                  : order?.quantity?.map((item: any, index: number) => (
                    <i key={item.name} className="flex">
                      {item?.quantity || 0} {item.name}
                    </i>
                  ))}
              </span>
            </p>
            {addon.fieldAmount && (
              <p className="flex">
                <span>Contribute to <TheField/></span>$
                {addon?.fieldAmount?.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            )}
            <Divider />
            <p className="flex">
              Discount
              <b>
                {Number(promo?.discount || 0).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}
              </b>
            </p>
            <p className="flex">
              Total amount
              <b>
                {Number(
                  amount - promo?.discount >= 0 ? amount - promo?.discount : 0
                ).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}
              </b>
            </p>
            <Divider />
            <div className="flex align-start" style={{ width: "100%" }}>
              <div style={{ flex: "0 0 calc(100% - 120px)" }}>
                <div
                  className={`field-form-items ${promo?.error ? "field-error-item" : ""
                    }`}
                >
                  <span>PROMO CODE</span>
                  <Input
                    allowClear
                    placeholder="Promo code..."
                    onChange={(e) =>
                      setPromo({
                        ...promo,
                        name: e?.target.value,
                        error: false,
                      })
                    }
                  />
                </div>
                <p className="error-text">
                  Invalid code, please try another one
                </p>
              </div>
              <Button
                style={{ flex: "0 0 100px", height: "56px", marginLeft: 20 }}
                onClick={() => couponValidate(promo.name)}
              >
                Apply
              </Button>
            </div>

            <Divider />
            <p className="tips">
              By confirming this payment, you agree to our{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thefield.org/terms-of-service/"
              >
                TERMS OF SERVICE
              </a>{" "}
            </p>

            <Button type="primary" block onClick={() => form.submit()}>
              confirm payment
            </Button>
          </div>
        </div>
      </ConsultationContainer>
      <Warning
        visible={warning}
        onClose={() => history.push("/explore-artist-community-events")}
        title="Registrations warning"
        content="You are not eligible to register for this event, please contact support@thefield.org for further assistance."
      />
    </HomeLayout>
  );
};

export default Consultation;
