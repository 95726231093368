import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    Modal,
    Select,
    Table,
    Tag,
    Tooltip,
} from "antd";
import dayjs from "dayjs";

import queryString from "query-string";
import { admin_router_finance_contribution_order } from "@/adminApp/routers";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { ReactComponent as Filter } from "@/images/filter.svg";
import { paymentTypes, statusTypes } from "@/types/enum";
import {
    contributionTag,
} from "@/adminApp/apis/finance-api";
import "./transactions.less";
import { myTransactions } from "@/api/donation-api";
import { localStorageGet } from "@/utils/storage";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { stateList } from "@/types/enum";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { TheField } from "@/components/GoogleTranslate";

export const Transactions = () => {
    const history = useHistory();
    const [tagList, setTagList] = useState<any[]>([]);
    const { store: { isMobile } } = useModel(view)
    const getTags = () => {
        contributionTag().then((data) => setTagList(data));
    };
    const { id: userId } = localStorageGet('info')
    //handler
    const handleExport = () => {
        const { page, limit, ...others } = params;

        window.open(
            `${process.env.REACT_APP_API_HOST
            }/contribution/transactions/export-csv?${queryString.stringify({
                ...others,
                userId,
                exportId: selectedRows.map((item: any) => item.id).join(","),
                tz: dayjs.tz.guess()
            })}`,
            "_blank"
        );
    };

    const handleViewOrder = (id: any) => {
        history.push(`/dashboard/transaction/${id}`);
    };

    useEffect(() => {
        getTags();
    }, []);

    // -search
    const column: any[] = [
        {
            title: "Product type",
            dataIndex: "productType",
            width: 150,
            fixed: "left",
        },
        {
            title: "Transaction type",
            width: 180,
            dataIndex: "donationType",
            fixed: isMobile ? undefined : "left",
        },
        {
            title: "Frequency",
            dataIndex: "frequency",
            width: 150,
            fixed: isMobile ? undefined : "left",
            render: (frequency: string) => {
                switch (frequency) {
                    case 'month':
                        return 'Recurring - Monthly'
                    case 'year':
                        return 'Recurring - Anually'
                    case 'once':
                    default:
                        return 'One time'
                }
            }
        },
        {
            title: "Receiver name",
            width: 150,
            dataIndex: "receiverName",
        },
        {
            title: "Amount",
            width: 100,
            dataIndex: "amount",
            render: (text: string, row: any) =>
                Number(text).toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                }),
        },
        {
            title: (
                <>
                  STATUS
                  <Tooltip
                    overlay={
                      <ul>
                        <li>
                          Paid - Donation successful; payment to artist scheduled or
                          complete.
                        </li>
                        <li>
                          Pending - Donation initiated; verifying payment information.
                        </li>
                        <li>
                          Check Pending - Check entered online; will process upon
                          receipt.
                        </li>
                        <li>
                          Processing - Donation successful; payment to artist scheduled
                          for 10 days later.
                        </li>
                        <li>
                          Failed - Donor’s payment information incomplete or declined.
                        </li>
                      </ul>
                    }
                  >
                    <QuestionCircleOutlined
                      size={12}
                      style={{ padding: 6, minWidth: 20 }}
                    />
                  </Tooltip>
                </>
              ),
            width: 110,
            dataIndex: "status",
            className: "artist-cell-roles",
            render: (text: string, _: any) => (
                <Tag color={statusTypes[text] ? statusTypes[text]?.color : "gray"}>
                    {statusTypes[text] ? statusTypes[text]?.label : text}
                </Tag>
            ),
        },
        {
            title: "payment method",
            dataIndex: "paymentMethod",
            width: 180,
            key: "payment",
            render: (text: string, row: any) => paymentTypes[text || "_null"],
        },
        {
            title: "Coupon code",
            width: 130,
            dataIndex: "coupon",
        },
        {
            title: "Invoice number",
            width: 150,
            dataIndex: "orderId",
        },
        {
            title: "Date created",
            width: 130,
            dataIndex: "createDate",
            render: (text: string, row: any) =>
                (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
        },
        {
            title: "",
            width: 80,
            fixed: isMobile ? undefined : "right",
            align: "center",
            key: "action",
            render: (_: string, row: any) => (
                <Dropdown
                    trigger={["click"]}
                    overlay={() => (
                        <>
                            <Menu>
                                <Menu.Item onClick={() => handleViewOrder(row.id)}>
                                    View order
                                </Menu.Item>
                                <Menu.Item onClick={() => history.push('/dashboard/message')}>
                                    Message Admin
                                </Menu.Item>
                            </Menu>
                        </>
                    )}
                >
                    <span className="ant-dropdown-link">
                        <FilterMore />
                    </span>
                </Dropdown>
            ),
        },
    ];
    const [text, setText] = useState("");
    const [params, setParams] = useState<any>({
        page: 1,
        limit: 10,
        keyword: "",
        status:[
            "processing",
            "check_pending",
            "pending",
            "paid"
        ]
    });

    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState<any[]>();
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [filterForm] = Form.useForm();
    const [filterModal, setFilterModal] = useState<any>(false);
    const handleViewFilter = () => {
        setFilterModal(true);
    };
    const handleFilterFinish = (values: any) => {
        setSelectedRows([]);
        setParams({
            ...params,
            ...values,
            page: 1,
        });
        setFilterModal(false);
    };

    const handleReset = () => {
        setText("");
        setParams({ page: 1, limit: 10, keyword: "" });
    };

    const getList = () => {
        myTransactions({ ...params })
            .then(({ items, meta }: any) => {
                setTableData(items);
                setTotal(meta.totalItems);
            })
            .catch(() => {
                setTotal(0);
                setTableData([]);
            });
    };
    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [params]);

    return (
        <div className="common-container transaction-container">
            <h1>Transaction History</h1>
            <div className={`search-container flex ${isMobile ? 'end' : ''}`}>
                <div className="left-wrap flex start ">
                    {
                        isMobile ? <div className="filter-item" onClick={handleViewFilter}>
                            <Filter />
                        </div> : <>
                            <div className="field-form-items">
                                <span>Product type</span>
                                <Select
                                    allowClear
                                    mode="multiple"
                                    value={params?.productType}
                                    bordered={false}
                                    onChange={(e) => {
                                        setSelectedRows([]);
                                        setTableData([]);
                                        setParams({
                                            ...params,
                                            page: 1,
                                            limit: 10,
                                            productType: e,
                                        });
                                    }}
                                    placeholder="Select product types"
                                >
                                    <Select.Option value="donation_to_the_field"> Donate to <TheField/> </Select.Option>
                                    <Select.Option value="donation_to_artist"> Donate to Artist </Select.Option>
                                    <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
                                    <Select.Option value="in_kind_donation_to_artist">In Kind Donation to Artist</Select.Option>
                                    <Select.Option value="fiscal_sponsorship">Fiscal Sponsorship</Select.Option>
                                    <Select.Option value="grant">Grant Rush Fee</Select.Option>
                                    <Select.Option value="consult_fee">Consultation Fee</Select.Option>
                                    <Select.Option value="misc_fee">Misc Fee</Select.Option>
                                    <Select.Option value="carryover_fee">Carryover Fee</Select.Option>
                                    <Select.Option value="ticket">Ticket</Select.Option>
                                </Select>
                            </div>
                            <div className="field-form-items">
                                <span>Transaction tag</span>
                                <Select
                                    allowClear
                                    value={params?.tag}
                                    placeholder="Select transaction tags"
                                    mode="multiple"
                                    bordered={false}
                                    onChange={(e) => {
                                        setSelectedRows([]);
                                        setTableData([]);
                                        setParams({
                                            ...params,
                                            page: 1,
                                            limit: 10,
                                            tagCondition: "one_of",
                                            tag: e,
                                        });
                                    }}
                                >
                                    {tagList.map((item: any) => (
                                        <Select.Option key={item.name} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="field-form-items">
                                <span>Status</span>
                                <Select
                                    allowClear
                                    mode="multiple"
                                    value={params?.status}
                                    bordered={false}
                                    onChange={(e) => {
                                        setSelectedRows([]);
                                        setTableData([]);
                                        setParams({
                                            ...params,
                                            page: 1,
                                            limit: 10,
                                            status: e,
                                        });
                                    }}
                                    placeholder="select... "
                                >
                                    {Object.entries(statusTypes).map((item: any) => (
                                        <Select.Option key={item[0]} value={item[0]}>
                                            {item[1].label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <span className="clear-all" onClick={handleReset}>
                                Clear
                            </span>
                        </>
                    }

                </div>
                <Dropdown
                    className="filter-item"
                    trigger={["click"]}
                    overlay={() => (
                        <Menu>
                            <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
                        </Menu>
                    )}
                >
                    <span className="ant-dropdown-link">
                        <FilterMore />
                    </span>
                </Dropdown>
            </div>
            {/* <div className="table-container"> */}
            <Table
                className="table-container"
                title={() => (
                    <div className="flex">
                        <span>Orders</span>
                        <Input.Search
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            onSearch={(e) => {
                                setTableData([]);
                                setParams({
                                    ...params,
                                    page: 1,
                                    limit: 10,
                                    keyword: e,
                                });
                            }}
                            placeholder="Search"
                        />
                    </div>
                )}
                rowKey="id"
                rowSelection={{
                    type: "checkbox",
                    onChange: (keys, rows) => {
                        setSelectedRows(rows);
                    },
                }}
                pagination={{
                    total,
                    current: params.page,
                    pageSize: params.limit,
                    simple: isMobile,
                    showTotal: (total: any) => `Total :  ${total} `,
                    onChange: (page, pageSize) =>
                        setParams({
                            ...params,
                            page,
                            limit: pageSize,
                        }),
                }}
                columns={column}
                dataSource={tableData}
                sticky
                showHeader={!!tableData?.length}
                scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
            />
            {/* </div> */}

            <Modal
                title={<h1 className="flex between">
                    <span>Filter</span>
                    <Button type="text" onClick={e => filterForm.resetFields()} style={{ marginLeft: 'auto' }}>reset</Button>
                </h1>}
                closable={false}
                className="common-modal admin-artist-modal"
                visible={filterModal}
                onOk={() => filterForm.submit()}
                okText="Apply"
                cancelText="cancel"
                onCancel={() => setFilterModal(false)}
                forceRender
                maskClosable
                centered
            >
                <Form
                    form={filterForm}
                    onFinish={handleFilterFinish}
                    layout="vertical"
                    name="form_filter"
                >
                    <Form.Item label="Product type" name="productType">
                        <Select
                            allowClear
                            mode="multiple"
                            value={params?.productType}
                            bordered={false}
                            placeholder="Select product types"
                        >
                            <Select.Option value="donation_to_the_field"> Donate to <TheField/> </Select.Option>
                            <Select.Option value="donation_to_artist"> Donate to Artist </Select.Option>
                            <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
                            <Select.Option value="in_kind_donation_to_artist">In Kind Donation to Artist</Select.Option>
                            <Select.Option value="fiscal_sponsorship">Fiscal Sponsorship</Select.Option>
                            <Select.Option value="grant">Grant Rush Fee</Select.Option>
                            <Select.Option value="consult_fee">Consultation Fee</Select.Option>
                            <Select.Option value="misc_fee">Misc Fee</Select.Option>
                            <Select.Option value="carryover_fee">Carryover Fee</Select.Option>
                            <Select.Option value="ticket">Ticket</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Transaction tag" name="tag">
                        <Select
                            allowClear
                            value={params?.tag}
                            placeholder="Select transaction tags"
                            mode="multiple"
                            bordered={false}
                        >
                            {tagList.map((item: any) => (
                                <Select.Option key={item.name} value={item.name}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Status" name="status">
                        <Select
                            allowClear
                            mode="multiple"
                            value={params?.status}
                            bordered={false}
                            placeholder="select... "
                        >
                            {Object.entries(statusTypes).map((item: any) => (
                                <Select.Option key={item[0]} value={item[0]}>
                                    {item[1].label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>

                <br />
                <br />
                <br />
                <br />
            </Modal>
        </div>
    );
};
