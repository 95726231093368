import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { Button, Divider, Form, Input, message } from "antd";
import { FacebookFilled, GoogleOutlined } from "@ant-design/icons";

import Password from "@/components/Password";
import { ReactComponent as LogoWhite } from "@/images/logo-white.svg";

import { useModel } from "use-reaction";
import { user, userLogin, getUser } from "@/model/user";

import { onClickGoogleLogin } from "@/utils/google";
import { loginFacebook, loginGoogle } from "@/api/sign-api";
import qs from "query-string";
import { localStorageSet } from "@/utils/storage";
import { GoogleLoginBtn } from "@/components/googleLoginBtn/google_login_btn";

const SignIn = () => {
  const history = useHistory();
  const { type = "artist" } = useParams() as any;
  const { to } = qs.parse(history.location.search);

  const { doAction } = useModel(user);

  const onFinish = async (values: any) => {
    try {
      const data = await doAction(userLogin, values);
      data !== "false" && (await handleRedirect());
    } catch (e) {}
  };

  const onFinishFailed = (e: any) => {
    message.error(e.errorFields[0].errors[0]);
  };
  
  const gglSuccess = async(idToken: string) => {
    const data = await loginGoogle({
     idToken
    });
    await doAction(userLogin, {
      save: true,
      token: data.token,
    });
    await handleRedirect();
  };


  //facebook login
  const onFbLoginClick = () => {
    (window as any)?.FB?.login(
      (res: any) => {
        if (res.status !== "connected" || !res.authResponse) {
          return false; //message.error("Sign in failure");
        }
        console.log(res.authResponse);
        loginFacebook({
          userId: res?.authResponse?.userID,
          type: type,
        }).then(async (data) => {
          console.log(data);
          await doAction(userLogin, {
            save: true,
            token: data.token,
          });
          localStorageSet("source", "facebook");
          await handleRedirect();
        });
      },
      {
        scope: "public_profile,email",
      }
    );
  };

  const handleRedirect = async () => {
    const data: any = await doAction(getUser);
    const isNewDonor = data?.roles?.length === 1 && data.roles?.[0] === 'donor'
    if (to) {
      window.location.href = to as string;
    } else if (data?.progress >= 4 || isNewDonor) {
      if (data.roles.includes("artist")) {
        history.push("/dashboard/income");
      } else {
        history.push("/dashboard/history");
      }
    } else {
      history.push(`/sign/${data?.roles[0]}/${data?.progress ? data.progress - 1 : 0}`);
    }
  };

  return (
    <div className="sign-container sign-up-container sign-in-container flex start">
      <div className="left-wrap">
        <span>
          <LogoWhite />
        </span>
        <i className="caption">Participants of Women's Weekend Film Challenge testing camera gear. </i>
        <i className="copy-right">Photo by Image Taker Studios.</i>
      </div>
      <div className="sign-up-wrap flex column start">
        <h1>Sign In</h1>
        {/*<h4>*/}
        {/*  Create an account today and recieve support for your artistic goals.*/}
        {/*  From fiscal sponsorship to grant reviews, The Field has your covered.*/}
        {/*</h4>*/}
        <div className="other-sign">
          <div className="btns">
          <Button
            size="large"
            className="facebook-btn"
            icon={<FacebookFilled />}
            onClick={() => onFbLoginClick()}
          >
            Using Facebook
          </Button>
          <GoogleLoginBtn
              clientId="523385719682-juj4ppk4ct4r3ilmfue18cfdd8ur6r34.apps.googleusercontent.com"
              onSuccess={gglSuccess}
              width={document.documentElement.clientWidth > 1023? 224 : 300}
              className="google-btn"
            />
          </div>
          {/* <Button
            size="large"
            className="google-btn"
            icon={<GoogleOutlined />}
            onClick={() => onClickGoogleLogin(onSignIn)}
          >
            Using Google
          </Button> */}
          <Divider orientation="left">OR</Divider>
        </div>
        <div className="sign-form">
          <Form
            layout="vertical"
            size="large"
            name="sign"
            // form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  message: "Enter email address",
                },
                {
                  type: "email",
                  message: "Enter valid email address",
                },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter email address" />
            </Form.Item>

            <div className="password-item">
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Enter password" }]}
              >
                <Password
                  popUpShow={false}
                  placeholder="Enter password"
                  visibilityToggle={false}
                />
              </Form.Item>
              <Link to="/sign/password">Forgot?</Link>
            </div>

            <Form.Item className="no-style">
              <Button block type="primary" htmlType="submit">
                Sign In
              </Button>
            </Form.Item>
            <h4>
              Not a member ?<Link to="/sign/up">Sign up now</Link>
            </h4>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
