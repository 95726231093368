import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Form, Input, Select } from "antd";
import { stateList } from "@/types/enum";
import StripePayCom from "@/components/StripePayment";
import { getBillInfo } from "@/api/donation-api";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { user } from "@/model/user";
import { useBookingContext } from "../data/BookingContext";
import { formatDuration } from "@/adminApp/pages/admin_consultation/scheduling/shared";
import {
  confirmConsultationPayment,
  updateConsultationPayment,
} from "@/adminApp/apis/consultation-api";
import { useRequest } from "ahooks";
import { couponCheck } from "@/api/consultation-api";
import { useUrlState } from "@/utils/util";
const { getData: getCountryList } = require("country-list");
const countryList = getCountryList();

const StripePay = React.forwardRef<any, any>((props, ref) => (
  <StripePayCom {...props} submitRef={ref} />
));

const emptyPromo: {
  name: string;
  discount: number;
  error?: boolean;
} = {
  name: "",
  discount: 0,
  error: false,
};

const Payment = () => {
  const [, setParams] = useUrlState({ id: undefined });
  const submitRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { store: userStore } = useModel(user);
  const { state } = useBookingContext();
  const clientSecret =
    state?.consultation?.order?.tradeInformation?.client_secret;
  const orderId = state.consultation?.order?.id;
  const amount = state.consultation?.order?.amount ?? 0;
  const updateRequest = useRequest(updateConsultationPayment, {
    manual: true,
  });
  const confirmRequest = useRequest(confirmConsultationPayment, {
    manual: true,
  });
  const [pay, setPay] = useState<any>({
    payment: "credit card",
    address: "",
    email: "",
    name: "",
    city: "",
    state: "",
    country: "US",
    code: "",
    coupon: "",
  });
  const {
    store: { isMobile },
  } = useModel(view);

  // coupon
  const [promo, setPromo] = useState(emptyPromo);
  const couponValidate = async (name: string) => {
    try {
      if (!name) {
        setPromo(emptyPromo);
        return emptyPromo;
      }
      const data = await couponCheck({
        name,
        productType: "consultation",
        price: amount,
      });
      const info = { name, discount: data?.discount, error: false };
      setPromo(info);
      return info;
    } catch (error) {
      const errorPromo = { ...emptyPromo, error: true };
      setPromo(errorPromo);
      return errorPromo;
    }
  };

  const confirmPayment = async () => {
    const result = await couponValidate(promo.name);
    if (!result.error) {
      await form.validateFields();
      const values = form.getFieldsValue();
      await setPay({ ...pay, ...values, coupon: result.name });
      if (result.discount > 0) {
        const data = await updateRequest.runAsync({
          orderId,
          coupon: result.name,
        });
        if (
          data.order?.amount &&
          +data.order?.amount <= +data.order?.discount
        ) {
          return afterConfirmPayment();
        }
      }
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
      await submitRef?.current?.submit();
    }
  };
  const afterConfirmPayment = async () => {
    const result = await confirmRequest.runAsync({ orderId });
    if (result.id) {
      state.consultation = { ...state.consultation, ...result };
      if (state.consultation.invitee) {
        state.step += 1;
        setParams({ id: undefined });
      }
    }
  };

  useEffect(() => {
    getBillInfo({}, true).then((data) => {
      const {
        billing_details: {
          address: { city, line1: address, postal_code: code, state, country },
          ...others
        },
      } = (Object.values(data)?.length && data) || {
        billing_details: {
          address: {
            city: "",
            line1: "",
            postal_code: "",
            state: "",
            country: "US",
          },
        },
      };
      let _pay = {
        ...others,
        city,
        address,
        code,
        state,
        country: country || "US",
        name: (userStore.info.firstName + " " + userStore.info.lastName).trim(),
        email: userStore.info.email,
      };
      setPay(_pay);
      form.setFieldsValue(_pay);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const amountString = Number(+amount - promo?.discount ?? 0).toLocaleString(
    "en",
    {
      style: "currency",
      currency: "USD",
    }
  );
  const discountString = Number(promo?.discount || 0).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className="consultation-payment">
      <div className="steps-content">
        <div className="steps-content-head !border-b-0 !block !py-5 !h-auto">
          <div className="steps-content-head-title">Payment</div>
          <div className="">
            Enter billing details before confirming your meeting time on the
            next page. You will only be charged if your request is accepted.
          </div>
        </div>
      </div>
      <div className={isMobile ? "" : "flex justify-between items-start"}>
        <div className="card-wrap px-[26px] py-[32px] mt-[22px] step-card flex-auto">
          <h2 className="text-[24px]">Payment information</h2>
          <h4>Credit card information</h4>
          <StripePay
            payId={+orderId}
            payInfo={pay}
            ref={submitRef}
            payType="pay"
            secretId={clientSecret}
            onNext={(data: any) => {
              if (
                data?.paymentIntent?.status === "requires_capture" &&
                data?.paymentIntent?.payment_method
              ) {
                afterConfirmPayment();
              }
            }}
          />
          <Divider />
          <h4>Billing information </h4>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            form={form}
            initialValues={pay}
            onValuesChange={(val) => {
              if (val.country) {
                form.setFieldsValue({ state: "" });
              }
            }}
          >
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter full Name",
                },
              ]}
            >
              <Input size="large" placeholder="Enter full Name" />
            </Form.Item>
            <Form.Item
              label="Email address"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Enter Email address",
                },
              ]}
            >
              <Input
                disabled={!!userStore.token}
                size="large"
                placeholder="Enter Email address"
              />
            </Form.Item>
            <Form.Item
              label="Billing Address"
              name="address"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  message: "Enter billing address",
                },
              ]}
            >
              <Input maxLength={30} size="large" placeholder="Address" />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter city",
                },
              ]}
            >
              <Input size="large" placeholder="City" />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Choose Country",
                },
              ]}
            >
              <Select showSearch size="large" placeholder="Country">
                {Object.values(countryList)?.map((item: any) => (
                  <Select.Option value={item.code} key={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="flex start align-start row-flex">
              <Form.Item noStyle dependencies={["country"]}>
                {({ getFieldValue }) => {
                  return getFieldValue("country") === "US" ? (
                    <Form.Item
                      label="State"
                      name="state"
                      className="select-item"
                      rules={[
                        {
                          required: true,
                          whitespace: false,
                          message: "Select State",
                        },
                      ]}
                    >
                      <Select showSearch size="large" placeholder="State">
                        {Object.values(stateList)?.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Enter State",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="State" />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                label="Zip code"
                name="code"
                rules={[
                  {
                    required: false,
                    whitespace: true,
                    message: "Enter zip code",
                  },
                ]}
              >
                <Input size="large" placeholder="Zip code" maxLength={6} />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="card-wrap px-[26px] py-[32px] mt-[22px] step-card flex-auto ml-10 max-w-[480px]">
          <h2 className="text-[24px]">Order summary</h2>
          <div className="summary-item">
            <div className="item-title">Duration</div>
            <div className="item-content">
              {formatDuration(state.consultation.duration)}
            </div>
          </div>
          <div className="summary-item">
            <div className="item-title">Consultation with</div>
            <div className="item-content">{state.staff.name}</div>
          </div>
          <div className="summary-item">
            <div className="item-title">Topic</div>
            <div className="item-content">{state.consultation.topic}</div>
          </div>
          <div className="summary-item">
            <div className="item-title">Price</div>
            <div className="item-content">{amountString}</div>
          </div>
          <Divider />
          <div className="summary-item">
            <div className="item-title">Discount</div>
            <div className="item-content">{discountString}</div>
          </div>
          <div className="flex text-black -mt-4">
            <span className="font-bold text-[16px]">Total amount</span>
            <span className="font-bold text-[24px]">{amountString}</span>
          </div>
          <Divider />
          <div className="flex align-start" style={{ width: "100%" }}>
            <div style={{ flex: "0 0 calc(100% - 120px)" }}>
              <div
                className={`field-form-items pr-4 w-full ${
                  promo?.error ? "field-error-item" : ""
                }`}
              >
                <span>PROMO CODE</span>
                <Input
                  allowClear
                  placeholder="Promo code..."
                  value={promo?.name}
                  onChange={(e) => {
                    setPromo({
                      ...promo,
                      name: e?.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
              <p className="error-text">Invalid code, please try another one</p>
            </div>
            <Button
              style={{ flex: "0 0 100px", height: "56px", marginLeft: 20 }}
              onClick={() => couponValidate(promo.name)}
            >
              Apply
            </Button>
          </div>
          <Divider />
          <p className="payment-tips">
            By confirming this payment, you agree to our{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/terms-of-service/"
              className="text-black font-extrabold text-[12px] underline"
            >
              TERMS OF SERVICE
            </a>
            . To ensurea continued service, we'll store and update (e.g., upon
            expiration) your payment method. Learn about{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/terms-of-service/"
              className="text-black font-extrabold text-[12px] underline"
            >
              HOW TO CANCEL
            </a>{" "}
            and our{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/terms-of-service/"
              className="text-black font-extrabold text-[12px] underline"
            >
              REFUND POLICY
            </a>
            . Prices may change.
          </p>
          <Button
            type="primary"
            htmlType="button"
            block
            loading={loading || confirmRequest.loading}
            className="mt-6"
            onClick={confirmPayment}
          >
            confirm payment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
