import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { Button, Divider, Form, Input, message } from "antd";
import { FacebookFilled, GoogleOutlined } from "@ant-design/icons";

import Password from "@/components/Password";
import { ReactComponent as LogoWhite } from "@/images/logo-white.svg";

import { useModel } from "use-reaction";
import { user, saveToken, loginOut } from "@/model/user";

import { onClickGoogleLogin } from "@/utils/google";
import { signFacebook, signGoogle, signUp } from "@/api/sign-api";
import { localStorageSet } from "@/utils/storage";
import { GoogleLoginBtn } from "@/components/googleLoginBtn/google_login_btn";
import { TheField } from "@/components/GoogleTranslate";

const SignUp = () => {
  const history = useHistory();
  const { type = "artist" } = useParams() as any;
  const { doAction } = useModel(user);
  const onFinish = (values: any) => {
    let _data = { ...values, type };
    signUp(_data).then(async (data) => {
      await doAction(saveToken, data.token);
      message.success("Account Created Successfully");
      history.push(`/sign/${type}/0`);
    });
  };

  const onFinishFailed = (e: any) => {
    message.error(e.errorFields[0].errors[0]);
  };

  //google login callback
  const gglSuccess = async (idToken: string) => {
    const data = await signGoogle({
      idToken,
      type: type || "artist",
    });
    await doAction(saveToken, data.token);
    await handleRedirect();
  };

  //facebook login
  const onFbLoginClick = () => {
    (window as any)?.FB?.login(
      (res: any) => {
        if (res.status !== "connected" || !res.authResponse) {
          return false; // message.error("Sign up failure");
        }
        signFacebook({
          userId: res?.authResponse?.userID,
          type: type || "artist",
        }).then(async (data) => {
          await doAction(saveToken, data.token);
          localStorageSet("source", "facebook");
          await handleRedirect();
        });
      },
      {
        scope: "public_profile,email",
      }
    );
  };

  const handleRedirect = async () => {
    history.push(`/sign/${type}/0`);
  };

  useEffect(() => {
    doAction(loginOut);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="sign-container sign-up-container flex start">
      <div className="left-wrap">
        <span>
          <LogoWhite />
        </span>
        <i className="caption">Musical group Brass Solidarity poses in front of a structure adorned with handwritten words which read, ‘Where There’s People There’s Power”. </i>
        <i className="copy-right">Photo by Rachel Austin</i>
      </div>
      <div className="sign-up-wrap flex column start">
        <h1>Create an account</h1>
        <h4>
          Create an account today and recieve support for your artistic goals.
          From fiscal sponsorship to grant reviews, <TheField/> has you covered.
        </h4>
        <div className="other-sign">
        <div className="btns">

          <Button
            size="large"
            className="facebook-btn"
            icon={<FacebookFilled />}
            onClick={() => onFbLoginClick()}
          >
            Using Facebook
          </Button>
          <GoogleLoginBtn
              clientId="523385719682-juj4ppk4ct4r3ilmfue18cfdd8ur6r34.apps.googleusercontent.com"
              onSuccess={gglSuccess}
              width={document.documentElement.clientWidth > 1023? 224 : 300}
              className="google-btn"
            />
          </div>
          {/* <Button
            size="large"
            className="google-btn"
            icon={<GoogleOutlined />}
            onClick={() => onClickGoogleLogin(onSignIn)}
          >
            Using Google
          </Button> */}
          <Divider orientation="left">OR</Divider>
        </div>
        <div className="sign-form">
          <Form
            layout="vertical"
            size="large"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  type: "email",
                  message: "Enter email address",
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Enter password" },
                {
                  pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/,
                  message: "invalid password",
                },
              ]}
            >
              <Password />
            </Form.Item>

            <h4>
              By clicking Create account, you agree to our
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thefield.org/terms-of-service/"
              >
                Terms of Service
              </a>{" "}
              and
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thefield.org/privacy-policy/"
              >
                Privacy Policy
              </a>
            </h4>

            <Form.Item className="no-style">
              <Button block type="primary" htmlType="submit">
                Create account
              </Button>
            </Form.Item>
            <h4>
              Already have an account? <Link to="/sign/in">Sign in </Link>
            </h4>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
