//created by Pegg on 2021/1/27
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu } from "antd";
import styled from "styled-components";

import { ReactComponent as HomeIcon } from "@/images/icon_menu_home.svg";
import { ReactComponent as ArtistIcon } from "@/images/icon_menu_artist.svg";
import { ReactComponent as DonationIcon } from "@/images/icon_menu_donation.svg";
import { ReactComponent as GrantIcon } from "@/images/icon_menu_grant.svg";
import { ReactComponent as SchedulingIcon } from "@/images/icon_scheduling.svg";
import { ReactComponent as EventIcon } from "@/images/icon_menu_event.svg";
// import { ReactComponent as ServiceIcon } from "@/images/icon_menu_service.svg";
import { ReactComponent as HistoryIcon } from "@/images/icon_menu_history.svg";
import { ReactComponent as FavoriteIcon } from "@/images/icon_menu_favorite.svg";
import { ReactComponent as MembershipIcon } from "@/images/icon_menu_membership.svg";
import { ReactComponent as MessageIcon } from "@/images/icon_menu_message.svg";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import { getUnreadCount } from "@/api/message-api";
import { getGrantNoteUnreadCount } from "@/api/grant-api";
import { GoogleTranslateTrigger, TheField } from "../GoogleTranslate";
import IconTranslate from '../../images/icons/icon_translate.svg'

const MenuLayoutContainer = styled.div`
  border-right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 60px;
    line-height: 60px;
    margin: 0;
    padding-left: 40px !important;
    &>span{
      margin-left:10px;
    }
  }
  .ant-menu-inline > .ant-menu-item.ant-menu-item-only-child {
    padding-left: 90px !important;
    a {
      padding: 0
    }
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0;
  }
  .ant-menu-inline > .ant-menu-item {
    display: flex;
    align-items: center !important;
    & > svg {
      margin-right: 16px;
    }
  }
  .ant-menu-submenu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
  }
`;

const { SubMenu } = Menu;

const MenuLayout = ({ onTabChg, disabled }: { onTabChg?: (key: string) => void, disabled?: boolean }) => {
  const history = useHistory();

  const { store } = useModel(user);

  const [selectKeys, setSelectKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [unreadMsgCnt, setUnreadMsgCnt] = useState(0)
  const [unreadNoteCnt, setUnreadNoteCnt] = useState(0)
  const handleClick = (e: any) => {
    if (e.key?.includes('fake-')) {
      return
    }
    if(e.key?.includes('language-')){
      GoogleTranslateTrigger(e);
      return
    }
    setSelectKeys([e.key]);
    history.push(e.key);
    onTabChg && onTabChg(e.key)
  };

  const openChange = (e: any) => {
    setOpenKeys(e.slice(-1));
  };

  useEffect(() => {
    if (disabled) {
      return
    }
    getUnreadCount().then(data => {
      setUnreadMsgCnt(data?.count || 0)
    })
    getGrantNoteUnreadCount().then(data => {
      setUnreadNoteCnt(data?.count || 0)
    })
  }, [])
  useEffect(() => {
    const select =
      history.location.pathname.match(/^\/dashboard(\/[a-z]+)/) ?? [];

    if (history.location.pathname.indexOf("/dashboard/membership") > -1) {
      const selectChild =
        history.location.pathname.match(/^\/dashboard((\/[a-z]+){2})/) ?? [];
      setSelectKeys([selectChild[0]]);
      setOpenKeys(["/dashboard/membership"]);
    } else if (history.location.pathname.indexOf("/dashboard/donation") > -1) {
      const selectChild =
        history.location.pathname.match(/^\/dashboard((\/[a-z]+){2})/) ?? [];
      setSelectKeys([selectChild[0]]);
      setOpenKeys(["/dashboard/donation"]);
    } else {
      setSelectKeys([select[0]]);
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);
  return (
    <MenuLayoutContainer className="menu-layout-container">
      <Menu
        onClick={handleClick}
        style={{ width: 300 }}
        openKeys={openKeys}
        selectedKeys={selectKeys}
        onOpenChange={openChange}
        mode="inline"
        disabled={disabled}
      >
        {(store?.roles?.includes("artist") && (
          <>
            <Menu.Item key="/dashboard/income" icon={<HomeIcon />}>
              Income overview
            </Menu.Item>
            <Menu.Item key="/dashboard/artist" icon={<ArtistIcon />}>
              Artist Profile
            </Menu.Item>
            <SubMenu
              key="/dashboard/donation"
              icon={<DonationIcon />}
              title="Donations"
            >
              <Menu.Item key="/dashboard/donation/overview">Overview</Menu.Item>
              <Menu.Item key="/dashboard/donation/list">
                Crowdfunding campaign
              </Menu.Item>
              <Menu.Item key="/dashboard/donation/general">
                General fundraising
              </Menu.Item>
              <Menu.Item key="/dashboard/donation/inKind">
                In-kind donations
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/dashboard/grant" icon={<GrantIcon />}>
              Grants & Solicitations
              <span className="red-dot" hidden={unreadNoteCnt === 0 || selectKeys.includes("/dashboard/grant")}></span>
            </Menu.Item>
            <Menu.Item key="/dashboard/scheduling" icon={<SchedulingIcon />}>
              Consultation Scheduling
            </Menu.Item>
            <SubMenu key="/dashboard/resources" title="Resources" icon={<EventIcon />}>
              <Menu.Item key="fake-faq">
                <a target="_blank" href="https://www.thefield.org/faq/" rel="noreferrer">
                  Artist F.A.Q.
                </a>
              </Menu.Item>
              <Menu.Item key="fake-knowledge">
                <a target="_blank" href="https://www.thefield.org/knowledge-base/" rel="noreferrer">
                  Knowledge Base
                </a>
              </Menu.Item>
              <Menu.Item key="fake-grants">
                <a target="_blank" href="/grants-and-opportunities">
                  Grants & Opportunities
                </a>
              </Menu.Item>
              <Menu.Item key="fake-news">
                <a target="_blank" href="https://www.thefield.org/news-and-events/" rel="noreferrer" >
                  News & Events from <TheField/>
                </a>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="dashboard/evt" title="Events" icon={<EventIcon />}>
              <Menu.Item key="/dashboard/event">
                Artist events
              </Menu.Item>
              <Menu.Item key="/explore-artist-community-events" >
                Community Calendar
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/dashboard/favorite" icon={<FavoriteIcon />}>
              Favorites
            </Menu.Item>
            <SubMenu
              key="/language"
              icon={<img src={IconTranslate} alt="" style={{marginRight:16}}/>}
              title="Language"
            >
              <Menu.Item key="language-en"><a href="#">English</a></Menu.Item>
              <Menu.Item key="language-es"><a href="#">Spanish</a></Menu.Item>
              <Menu.Item key="language-zh-CN"><a href="#">Chinese</a></Menu.Item>
              <Menu.Item key="language-fr"><a href="#">French</a></Menu.Item>
              <Menu.Item key="language-ja"><a href="#">Japanese</a></Menu.Item>
              <Menu.Item key="language-ar"><a href="#">Arabic</a></Menu.Item>
            </SubMenu>
            <SubMenu
              key="/dashboard/membership"
              icon={<MembershipIcon />}
              title="Membership"
            >
              <Menu.Item key="/dashboard/membership/overview">
                Overview
              </Menu.Item>
              <Menu.Item key="/dashboard/membership/fiscal">
                Fiscal sponsorship
              </Menu.Item>
              <Menu.Item key="/dashboard/membership/payable">
                Payable settings
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/dashboard/message" icon={<MessageIcon />}>
              Message
              <span className="red-dot" hidden={unreadMsgCnt === 0 || selectKeys.includes("/dashboard/message")}></span>
            </Menu.Item>
          </>
        )) ||
          null}

        <Menu.Item key="/dashboard/history" icon={<HistoryIcon />}>
          Donation History
        </Menu.Item>
      </Menu>
    </MenuLayoutContainer>
  );
};

export default MenuLayout;
