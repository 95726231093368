//created by Pegg on 2021/1/21
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Button,
  Carousel,
  Dropdown,
  Menu,
  message,
  Popover,
  Anchor,
  Radio,
  Space,
  Modal as AntdModal
} from "antd";
import {
  FieldTimeOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import HomeLayout from "@/components/layouts/HomeLayout";
import CrowdItem from "@/pages/homepage/donation/CrowdItem";
import EditBar from "@/components/Profile/EditBar";
import EditFormModal from "@/components/Profile/EditForm";
import avatar from "@/images/default_head.jpg";
// import about_img from "@/images/about_img.svg";
// import media from "@/images/media_default.jpg";

import { ReactComponent as Play } from "@/images/play_btn.svg";

import { ReactComponent as Facebook } from "@/images/facebook.svg";
import { ReactComponent as Twitter } from "@/images/twitter.svg";
import { ReactComponent as Instagram } from "@/images/instagram.svg";
import { ReactComponent as Tiktok } from "@/images/tiktok.svg";
import { ReactComponent as Youtube } from "@/images/youtube.svg";
import { ReactComponent as Vimeo } from "@/images/vimeo.svg";
import { ReactComponent as LinkedIn } from "@/images/linkedin.svg";
import { ReactComponent as Website } from "@/images/website.svg";

import "@/styles/home/user.less";

import { getArtistById, putArtistSection, reportArtist } from "@/api/artist-api";
import { delInstInfo, getMedias, putInstInfo } from "@/api/instagram-api";
import { getToken, localStorageGet, localStorageSet } from "@/utils/storage";
import dayjs from "dayjs";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { deleteProject } from "@/api/artist-api";
import { useModel } from "use-reaction";
import { user as UserModel } from "@/model/user";
import { donation } from "@/model/donation";
import { getEnv } from "@/libs/cfg";

import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";

// import {
//   FacebookShareButton,
//   InstapaperShareButton,
//   TwitterShareButton,
//   FacebookIcon,
//   InstapaperIcon,
//   TwitterIcon,
// } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "@/components/Modal/Info";
import { addFavorite } from "@/api/favorite-api";

import { ReactComponent as EditIcon } from "@/images/icon_edit.svg";
import Caption from "@/components/Image/caption";
import GAEvent from "@/utils/GAEvent";
import { NoTranslate } from "@/components/GoogleTranslate";
import { AppContainerStyle } from "@/styles/util";

// import queryString from "query-string";

interface InstaMedia {
  id: string;
  caption?: string;
  media_type?: string;
  media_url?: string;
  permalink?: string;
  thumbnail_url?: string;
  timestamp?: string;
  username?: string;
}

const PlayMemo = forwardRef(({ url = "" }: { url: string }, ref) => {
  const playRef = useRef<any>();
  useImperativeHandle(ref, () => ({
    play: playRef?.current?.plyr?.play,
    pause: playRef?.current?.plyr?.pause,
  }));
  useEffect(() => {
    console.log("url");
  }, [url]);

  const playerHoc = useMemo(
    () => (
      <Plyr
        ref={playRef}
        source={
          url?.indexOf("youtu") > -1
            ? {
                type: "video",
                sources: [
                  {
                    src:
                      url
                        ?.match(
                          /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
                        )
                        ?.pop() || "acc",
                    provider: "youtube",
                  },
                ],
              }
            : {
                type: "video",
                sources: [
                  {
                    src: url?.match(/vimeo\.com\/([\d]{8,})/)?.pop() || "acc",
                    provider: "vimeo",
                  },
                ],
              }
        }
      />
    ),
    [url, ref]
  );
  return playerHoc;
});

const Profile = () => {
  const history = useHistory();
  const { edit, id } = useParams() as any;
  const sliderRef = useRef<any>(null);

  const { resetModel } = useModel(donation);
  const { store } = useModel(UserModel);

  const [user, setUser] = useState<any>();
  //about:text-model
  const [biographyFormat, setBiographyFormat] = useState("plain");
  const [show, setShow] = useState<boolean | string>(false);
  const [showCam, setShowCam] = useState<boolean>(false);

  const videoRef = useRef<any>(null);
  const [state, setState] = useState(1);
  const [play, setPlay] = useState<number>(-1);
  const [url, setUrl] = useState<string>("");
  const onPlay = (index: number) => {
    setPlay(index);
  };
  const [showReport, setShowReport] = useState(false);
  const [reportReason, setReportReason] = useState("spam");

  useEffect(() => {
    if (play > -1) {
      // setTimeout(() => {
      console.log(videoRef);
      videoRef?.current?.play && videoRef?.current?.play();
      // }, 2000);
    }
  }, [play]);

  useEffect(() => {
    if (user) {
      setPlay(-1);
      setUrl(user?.videos ? user?.videos[state - 1]?.url : "");
      videoRef?.current?.pause && videoRef?.current?.pause();
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    setPlay(-1);
    const _temp = state;
    setState(1);
    setUrl(user?.videos && user?.videos[0]?.url ? user?.videos[0]?.url : "");
    _temp !== 1 && sliderRef?.current?.goTo(0, true);
    // eslint-disable-next-line
  }, [user]);

  const getArtistInfo = () => {
    let _data = {
      id,
    };
    getArtistById(_data, !!edit).then((data) => {
      let {
        allowMessage,
        allowShare,
        avatar,
        photos = [],
        videos = [],
        biographyFormat,
        ...others
      } = data;
      setUser({
        crowdfunding: "",
        ...others,
        videos,
        avatar,
        setting: [allowMessage ? "message" : "", allowShare ? "share" : ""],
        avatarList: avatar?.split(",").map((item: any) => ({
          uid: (Math.random() * 1000000000).toFixed(0),
          status: "done",
          name: item,
          url: item,
        })),
        photosList: photos?.map((item: any) => ({
          uid: (Math.random() * 1000000000).toFixed(0),
          status: "done",
          name: item,
          url: item,
        })),
      });
      if (data) {
        GAEvent("view_item", {
          items: [
            {
              item_name: others.displayName, // Name or ID is required.
              item_id: id,
              quantity: 1,
            },
          ],
        });
      }
      setBiographyFormat(biographyFormat);
      data.instagram?.insId && setInstaInfo(data.instagram);
      // const { tab, projectId } = queryString.parse(history.location.search);
      // if (tab === "project") {
      //   const _index = data?.projects?.findIndex(
      //     (item: any) => item?.id === Number(projectId)
      //   );
      //   console.log(_index, "index");
      //   setTimeout(() => {
      //     setShow(`project-${_index}`);
      //     // history.replace(history.location.pathname + "#project-section");
      //   }, 3000);
      // }
    });
  };

  const handleSection = (_data?: any) => {
    putArtistSection(_data).then(() => getArtistInfo());
  };

  const projectDelete = (id: number) => {
    deleteProject(id).then((data) => {
      getArtistInfo();
      message.success("delete success");
    });
  };

  useEffect(() => {
    if (edit && !!!store?.token) {
      history.push(`/sign/in?redirect=${history.location.pathname}`);
    }
    getArtistInfo();
    resetModel();

    // eslint-disable-next-line
  }, []);

  const [instaInfo, setInstaInfo] = useState<any>({
    insId: "",
    insName: "",
    createDate: "",
  });
  const [instaMedias, setInstaMedias] = useState<InstaMedia[]>([]);
  const instaUser = localStorageGet("inst_user");
  if (instaUser?.insId) {
    // back from instagram auth
    const { insId, insName, token } = instaUser as { [k: string]: string };
    localStorageSet("inst_user", null);
    // put instainfo to BE
    putInstInfo({ id: parseInt(id), insId, insName, token }).then((res) => {
      if (res.insId) {
        setInstaInfo(res);
      }
    });
  }

  useEffect(() => {
    if (id && instaInfo.insId) {
      getMedias(id, 4).then((res) => {
        if (res.medias) {
          setInstaMedias(res.medias);
        }
      });
    }
    // eslint-disable-next-line
  }, [instaInfo.insId]);

  const profileLink = `${getEnv("WEB_HOST")}/profile/${(
    user?.displayName || "-"
  ).replace(/[^\w]/g, "-")}/${id}`;

  const [btnDisabled, setBtnDisabled] = useState(false);
  const prevVideo = () => {
    if (btnDisabled) return false;
    setBtnDisabled(true);
    sliderRef?.current?.prev();
    setState(state === 1 ? user?.videos?.length : state - 1);
  };
  const nextVideo = () => {
    if (btnDisabled) return false;
    setBtnDisabled(true);
    sliderRef?.current?.next();
    setState(state === user?.videos?.length ? 1 : state + 1);
  };
  useEffect(() => {
    setTimeout(() => {
      setBtnDisabled(false);
    }, 1000);
  }, [state]);
  return (
    <div className="full-profile-page">
      {edit && (
        <div className="preview-header-container">
          <i>Live site preview</i>
          <a href={profileLink} target="_blank" rel="noreferrer">
            <Button type="primary">View public profile</Button>
          </a>
        </div>
      )}
      <AppContainerStyle/>
      <HomeLayout>
        <div className="user-profile-container flex column start align-start">
          <section className="user-info flex start align-end">
            <div className="">
              <h1 translate="no">{user?.displayName}</h1>
              <h4>
                {user?.primaryTag}{" "}
                {user?.primaryTag && !!user?.tags?.length && " • "}
                {user?.tags?.join(" • ")?.replace(/Other -/g, "")}
              </h4>
              <p>
                {user?.sponsorship?.status === "completed" && (
                  <Button
                    type="primary"
                    onClick={() => {
                      history.push(`/home/donation/general/${id}/0`);
                    }}
                    disabled={edit}
                  >
                    Donate now
                  </Button>
                )}

                <span className="tools">
                  {getToken() && (
                    <>
                      <Button
                        onClick={async (e) => {
                          try {
                            await addFavorite(id, "artist");
                            message.success("Follow success");
                          } catch (e) {}
                        }}
                        disabled={edit}
                        size="large"
                      >
                        Follow
                      </Button>
                      {/*<Button*/}
                      {/*  disabled={edit}*/}
                      {/*  size="large"*/}
                      {/*  onClick={() => history.push("/dashboard/message")}*/}
                      {/*>*/}
                      {/*  Message*/}
                      {/*</Button>*/}
                    </>
                  )}
                  <Popover
                    overlayClassName="popover-btn-wrap"
                    content={
                      <div className="share">
                        {/*<p>*/}
                        {/*  <FacebookShareButton*/}
                        {/*    url="https://www.google.com"*/}
                        {/*    quote="share"*/}
                        {/*  >*/}
                        {/*    <FacebookIcon size={32} round />*/}
                        {/*  </FacebookShareButton>*/}
                        {/*  <InstapaperShareButton*/}
                        {/*    url="https://www.google.com"*/}
                        {/*    title="share"*/}
                        {/*  >*/}
                        {/*    <InstapaperIcon size={32} round />*/}
                        {/*  </InstapaperShareButton>*/}
                        {/*  <TwitterShareButton*/}
                        {/*    url="https://www.google.com"*/}
                        {/*    title="share"*/}
                        {/*  >*/}
                        {/*    <TwitterIcon size={32} round />*/}
                        {/*  </TwitterShareButton>*/}
                        {/*</p>*/}
                        <p>
                          <span>or copy Url</span>
                          <CopyToClipboard
                            text={profileLink}
                            onCopy={() => message.success("Copy success")}
                          >
                            <p className="flex">
                              <i>{profileLink}</i>
                              <i>Copy</i>
                            </p>
                          </CopyToClipboard>
                        </p>
                      </div>
                    }
                  >
                    <Button disabled={edit} size="large">
                      Share
                    </Button>
                  </Popover>

                  {user?.events?.length && user?.sectionSettings?.events ? (
                    <Button className="event-btn" disabled={edit} size="large">
                      <Anchor affix={false}>
                        <Anchor.Link href="#event-section" title="events" />
                      </Anchor>
                    </Button>
                  ) : (
                    ""
                  )}
                  <Dropdown
                    className="filter-item"
                    trigger={["click"]}
                    placement="bottomRight"
                    overlay={() => (
                      <Menu>
                        <Menu.Item onClick={() => setShowReport(true)}>Report this profile</Menu.Item>
                      </Menu>
                    )}
                  >
                    <span>
                      <FilterMore />
                    </span>
                  </Dropdown>
                </span>
                <Dropdown
                  className="mb-btn-item"
                  trigger={["click"]}
                  overlay={() => (
                    <Menu>
                      {getToken() && (
                        <Menu.Item
                          onClick={async (e) => {
                            try {
                              await addFavorite(id, "artist");
                              message.success("Follow success");
                            } catch (e) {}
                          }}
                        >
                          Follow
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <div>
                          <CopyToClipboard
                            text={profileLink}
                            onCopy={() =>
                              message.success("Copy share url success")
                            }
                          >
                            <span>Share</span>
                          </CopyToClipboard>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <Anchor affix={false}>
                          <Anchor.Link href="#event-section" title="Events" />
                        </Anchor>
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <span className="ant-dropdown-link">
                    <FilterMore />
                  </span>
                </Dropdown>
              </p>
            </div>
            <div className="img-wrap">
              <Caption src={user?.avatar || avatar} />
            </div>
            {edit && (
              <EditBar
                hideButton
                onShow={() => setShow("info")}
                text="Artist information section"
              />
            )}
          </section>
          {((user?.sectionSettings?.crowdfunding &&
            user?.crowdfunding &&
            !edit) ||
            edit) && (
            <section className="donate-info">
              {edit && (
                <EditBar
                  hide={user?.sectionSettings?.crowdfunding}
                  onHide={(val) =>
                    handleSection({
                      crowdfunding: val,
                    })
                  }
                  onShow={() => {
                    if (user.sponsorship?.status === "completed") {
                      if (user?.crowdfunding?.id) {
                        history.push(
                          `/dashboard/donation/create/${user?.crowdfunding?.id}`
                        );
                        // setShow("donate");
                      } else {
                        history.push("/dashboard/donation/create");
                      }
                    } else {
                      setShowCam(true);
                    }
                  }}
                  text="Crowdfunding campaign section"
                />
              )}
              <p className="text">CROWDFUNDING campaign</p>
              <h2 translate="no">{user?.crowdfunding?.name || "N/A"}</h2>
              <h4 translate="no">{user?.crowdfunding?.summary}</h4>
              <CrowdItem
                goal={user?.crowdfunding?.goalAmount}
                raised={user?.crowdfunding?.currentAmount}
                donors={user?.crowdfunding?.donateNumber}
                time={
                  user?.crowdfunding?.time || {
                    start: user?.crowdfunding?.startDate,
                    end: user?.crowdfunding?.endDate,
                  }
                }
                timezone={user?.crowdfunding?.time?.timezone || "EST"}
                status={
                  user?.crowdfunding?.status !== "live" ||
                  user.sponsorship?.status !== "completed"
                }
                viewTo={
                  user?.crowdfunding?.name
                    ? `/home/donation/crowd/preview/${
                        user?.crowdfunding?.id
                      }/${user?.crowdfunding?.name?.replace(/[^\w]/g, "-")}`
                    : ""
                }
                to={`/home/donation/crowd/${user?.crowdfunding?.id}/0`}
              />
            </section>
          )}

          {((user?.sectionSettings?.about &&
            user?.biography &&
            user?.photosList &&
            !edit) ||
            edit) && (
            <section className="about-info">
              {edit && (
                <EditBar
                  hide={user?.sectionSettings?.about}
                  model="about"
                  onHide={(val) =>
                    handleSection({
                      about: val,
                    })
                  }
                  text="About me section"
                  onShow={() => setShow("about")}
                  modelData={{
                    biographyFormat,
                    setBiographyFormat,
                  }}
                />
              )}

              <h1>About the artist</h1>
              <div className="about-wrap flex start align-start">
                {biographyFormat === "plain" ? (
                  <>
                    <Caption
                      src={user?.photosList && user?.photosList[0]?.url}
                    />
                    {/*<img*/}
                    {/*  src={*/}
                    {/*    (user?.photosList && user?.photosList[0]?.url) ||*/}
                    {/*    about_img*/}
                    {/*  }*/}
                    {/*  alt="about me"*/}
                    {/*/>*/}
                    <span className="about-text">
                      {user?.biography
                        ? JSON.parse(JSON.stringify(user?.biography))
                            .replace(/(<([^>]+)>)/gi, "")
                            ?.split("\n")
                            ?.map((item: any, index: number) => (
                              <i className="i-block-item" key={index}>
                                {item}
                              </i>
                            ))
                        : "Tell your story"}
                    </span>
                  </>
                ) : (
                  <div
                    className="about-text rich-text"
                    dangerouslySetInnerHTML={{
                      __html: user?.biography || "Tell your story",
                    }}
                  />
                )}
              </div>
            </section>
          )}

          {((user?.sectionSettings?.videos && user?.videos?.length && !edit) ||
            edit) && (
            <section className="media-info">
              {edit && (
                <EditBar
                  hide={user?.sectionSettings?.videos}
                  onHide={(val) =>
                    handleSection({
                      videos: val,
                    })
                  }
                  text="Featured videos section"
                  onShow={() => setShow("media")}
                />
              )}

              <Carousel
                ref={sliderRef}
                className="media-list"
                centerMode={true}
                infinite
                arrows
                touchMove={false}
                centerPadding="0"
                dots={false}
                slidesToScroll={1}
                slidesToShow={1}
              >
                {user?.videos?.map((item: any, index: number, arr: any[]) => (
                  <div className="media-list-cell" key={item}>
                    <div className="media-list-content">
                      <span
                        className={`poster-wrap ${
                          state - 1 !== index ? "hidden" : ""
                        }`}
                      >
                        {item?.url?.indexOf("youtu") > -1 ? (
                          <img
                            src={`//img.youtube.com/vi/${item?.url
                              ?.match(
                                /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
                              )
                              ?.pop()}/0.jpg`}
                            alt="media"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <img
                            src={`//vumbnail.com/${item?.url
                              ?.match(/vimeo\.com\/([\d]{8,})/)
                              ?.pop()}.jpg`}
                            alt="vimeo_img"
                            style={{ width: "100%" }}
                          />
                        )}
                      </span>

                      {state === index + 1 && (
                        <div
                          className={`media-description ${
                            state === index + 1 ? "show" : ""
                          }`}
                        >
                          <span>
                            {`0${index + 1}`} / {`0${arr.length}`}
                          </span>
                          <h3>{item.title}</h3>
                          <span>
                            {item.description
                              ? item.description
                                  ?.split("\n")
                                  ?.map((item: any, index: number) => (
                                    <i className="i-block-item" key={index}>
                                      {item}
                                    </i>
                                  ))
                              : "Video description..."}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Carousel>
              <div className="line-wrap">
                <h1>Featured Media</h1>
                {play === -1 && (
                  <Play onClick={() => onPlay(state)} className="play_btn" />
                )}

                {(user?.videos?.length && (
                  <div className="media-button">
                    <Button
                      disabled={btnDisabled}
                      type="dashed"
                      onClick={() => !btnDisabled && prevVideo()}
                      icon={<LeftOutlined />}
                    />
                    <Button
                      disabled={btnDisabled}
                      type="dashed"
                      onClick={() => !btnDisabled && nextVideo()}
                      icon={<RightOutlined />}
                    />
                  </div>
                )) ||
                  ""}

                <div
                  className={`play-container ${
                    play > -1 && user?.videos ? "active" : ""
                  }`}
                >
                  <PlayMemo ref={videoRef} url={url} />
                </div>
              </div>
              {!!!user?.videos?.length && (
                <div className="no-media media-list-cell">
                  <div className="media-list-content">
                    <div className="poster-wrap">
                      <Play />
                    </div>

                    <div className="media-description show">
                      <span>01 / 01</span>
                      <h3>Video name</h3>
                      <span>Video description...</span>
                    </div>
                  </div>
                </div>
              )}
            </section>
          )}

          {((user?.sectionSettings?.projects &&
            user?.projects?.length &&
            !edit) ||
            edit) && (
            <section className="project-info" id="project-section">
              {edit && (
                <EditBar
                  hide={user?.sectionSettings?.projects}
                  onHide={(val) =>
                    handleSection({
                      projects: val,
                    })
                  }
                  hideShowButton={true}
                  text="Projects section"
                />
              )}
              <h1>Projects</h1>
              <div className="masonry">
                {user?.projects?.map((item: any, index: number) => (
                  <div className="children-item" key={item.id}>
                    <div className="item">
                      {item?.project_feature_photos && (
                        <img
                          src={
                            item?.project_feature_photos &&
                            item?.project_feature_photos[0]
                          }
                          alt="project"
                          onClick={() =>
                            history.push(`/home/project/${item.id}`)
                          }
                        />
                      )}
                      <h2>{item.project_title}</h2>
                      <span>{item.project_synopsis}</span>
                      <Link to={`/home/project/${item.id}`}>view detail</Link>
                      {edit && (
                        <span className="edit-btn">
                          <Dropdown
                            className="mb-btn-item"
                            trigger={["hover"]}
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  onClick={() => setShow(`project-${index}`)}
                                >
                                  edit
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => projectDelete(item.id)}
                                >
                                  delete
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <span className="ant-dropdown-link">
                              <FilterMore />
                            </span>
                          </Dropdown>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                {edit && (
                  <div className="no-item item">
                    <div className="bg-item">
                      <Button type="primary" onClick={() => setShow("project")}>
                        add project
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}

          {((user?.sectionSettings?.events && user?.events?.length && !edit) ||
            edit) && (
            <section className="event-info" id="event-section">
              {edit && (
                <EditBar
                  hide={user?.sectionSettings?.events}
                  hideShowButton
                  onHide={(val) =>
                    handleSection({
                      events: val,
                    })
                  }
                  text="Upcoming events section"
                  onShow={() => setShow("event")}
                />
              )}
              <h1>Upcoming events</h1>
              <div className="event-list">
                {user?.events?.map((item: any) => (
                  <div className="flex event-cell" key={item.id}>
                    <img src={item?.thumbnail} alt="event" />
                    <div className="flex">
                      <div className="event-content flex column align-start icon-block-wrap">
                        <h2>{item?.name}</h2>
                        <p>
                          <FieldTimeOutlined />{" "}
                          <span>
                            {item?.time?.startTime
                              ? dayjs(item?.time?.startTime).format("HH:mm  A")
                              : "N/A"}{" "}
                            –{" "}
                            {item?.time?.endTime
                              ? dayjs(item?.time?.endTime).format("HH:mm  A")
                              : "N/A"}{" "}
                            {item?.time?.timezone ?? "N/A"}・
                            {item?.time?.startDate
                              ? dayjs(item?.time?.startDate).format(
                                  "dddd, MMMM D, YYYY"
                                )
                              : "N/A"}
                          </span>
                        </p>
                        <p>
                          <EnvironmentOutlined />
                          <span
                            className="icon-block-wrap-address"
                            title={
                              [
                                item?.location?.address,
                                item?.location?.city,
                                item?.location?.state,
                                item?.location?.zipCode,
                              ]
                                .filter((item) => item)
                                .join(", ") || item?.location?.url
                            }
                          >
                            {[
                              item?.location?.address,
                              item?.location?.city,
                              item?.location?.state,
                              item?.location?.zipCode,
                            ]
                              .filter((item) => item)
                              .join(", ") || item?.location?.url}
                          </span>
                        </p>
                        <p>
                          <DollarCircleOutlined />{" "}
                          <span> {item?.price ?? "N/A"}</span>
                        </p>
                      </div>
                      <div className="flex column event-button">
                        <Button
                          disabled={edit}
                          onClick={() =>
                            history.push(
                              `/event/${item.id}/${item.name.replace(
                                /[^\w]/g,
                                "-"
                              )}`
                            )
                          }
                        >
                          View detail
                        </Button>
                        <Button
                          disabled={edit}
                          type="primary"
                          onClick={() =>
                            window.open(item?.button?.url, "_blank")
                          }
                        >
                          {item?.button?.label}
                        </Button>
                      </div>
                    </div>
                    {edit && (
                      <span
                        className="edit-btn"
                        onClick={() =>
                          history.push(`/dashboard/event/create/${item.id}`)
                        }
                      >
                        <EditIcon />
                      </span>
                    )}
                  </div>
                ))}
                {edit && (
                  <div className="flex event-cell no-item">
                    <div className="bg-item">
                      <Button
                        type="primary"
                        onClick={() => history.push("/dashboard/event")}
                      >
                        Add event
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}

          {((user?.sectionSettings?.socialMedia &&
            instaMedias?.length &&
            !edit) ||
            edit) && (
            <section className="instagram-info">
              {edit && (
                <EditBar
                  hide={user?.sectionSettings?.socialMedia}
                  onHide={(val) =>
                    handleSection({
                      socialMedia: val,
                    })
                  }
                  text="Website and Social Media"
                  onShow={() => setShow("instagram")}
                />
              )}
              <h1 className="flex column start align-start">
                <span>follow me on instagram</span>
                {instaInfo.insName && <i>@{instaInfo.insName}</i>}
              </h1>
              <div className="flex start instagram-list">
                {instaMedias?.length ? (
                  instaMedias.map((m, index) => (
                    <span key={index}>
                      <img
                        src={m.thumbnail_url || m.media_url}
                        alt="ins-cover"
                      />
                    </span>
                  ))
                ) : (
                  <span>no instagram medias</span>
                )}
              </div>
            </section>
          )}

          {user?.sectionSettings?.socialMedia && (
            <section className="follow">
              <div className="flex">
                <h1>Follow <NoTranslate>{user?.displayName}</NoTranslate></h1>
                <span
                  className="flex social-icon"
                  style={{ flex: "0 0 500px" }}
                >
                  {user?.website && (
                    <a href={user?.website} target="_blank" rel="noreferrer">
                      <Website />
                    </a>
                  )}
                  {user?.linkedin && (
                    <a href={user?.linkedin} target="_blank" rel="noreferrer">
                      <LinkedIn />
                    </a>
                  )}
                  {instaInfo.insName && (
                    <a
                      href={`https://www.instagram.com/${instaInfo.insName}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram />
                    </a>
                  )}
                  {user?.tiktok && (
                    <a href={user?.tiktok} target="_blank" rel="noreferrer">
                      <Tiktok />
                    </a>
                  )}
                  {user?.facebook && (
                    <a href={user?.facebook} target="_blank" rel="noreferrer">
                      <Facebook />
                    </a>
                  )}
                  {user?.twitter && (
                    <a href={user?.twitter} target="_blank" rel="noreferrer">
                      <Twitter />
                    </a>
                  )}
                  {user?.youtube && (
                    <a href={user?.youtube} target="_blank" rel="noreferrer">
                      <Youtube />
                    </a>
                  )}
                  {user?.vimeo && (
                    <a href={user?.vimeo} target="_blank" rel="noreferrer">
                      <Vimeo />
                    </a>
                  )}
                </span>
              </div>
            </section>
          )}
        </div>

        <EditFormModal
          data={{ ...user, biographyFormat }}
          show={show}
          insName={instaInfo.insName}
          insDate={
            instaInfo.createDate
              ? dayjs(instaInfo.createDate).format("MM/DD/YYYY hh:mm")
              : ""
          }
          onCancel={() => setShow(false)}
          removeInsta={() => {
            delInstInfo(id).then((res) => {
              if (res?.success) {
                setInstaInfo({ insId: "", insName: "", createDate: "" });
                setInstaMedias([]);
              }
            });
          }}
          onOk={() => {
            getArtistInfo();
            setShow(false);
          }}
        />
        <Modal
          visible={showCam}
          width={420}
          footer={[
            <Button key="back" onClick={() => setShowCam(false)}>
              LATER
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => history.push("/dashboard/membership/fiscal")}
            >
              Apply now
            </Button>,
          ]}
        >
          <h2>1 more step to set up your campaign </h2>
          <p>
            In order to set up a campaign, you will have to apply for Fiscal
            Sponsorship
          </p>
        </Modal>
        <AntdModal
          visible={showReport}
          onOk={() => {
            setShowReport(false);
            reportArtist(id, reportReason).then((res) => {
              res?.success && message.success("Report successfully submitted!");
            });
          }}
          onCancel={(e) => setShowReport(false)}
          className="modal-report"
          cancelText="CANCEL"
          okText="REPORT"
          title={<h1>Report Profile</h1>}
        >
          <Radio.Group
            onChange={(e) => setReportReason(e.target.value)}
            value={reportReason}
          >
            <Space direction="vertical">
              <Radio key="spam" value="spam">
                Spam
              </Radio>
              <Radio key="inappropriate" value="inappropriate">
                Inappropriate
              </Radio>
              <Radio key="legal" value="legal">
                Copyright or other legal complaints
              </Radio>
            </Space>
          </Radio.Group>
        </AntdModal>
      </HomeLayout>
    </div>
  );
};

export default Profile;
